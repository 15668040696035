<ng-template appPlaceholderUserModalDirective></ng-template>

<div class="full-schema-edition"
     data-cy="full-schema-edition-div">
  <div class="full-schema-edition__current-schema schemas__list__record global-border-radius">
    <div
      class="full-schema-edition__current-schema__information global-flex global-flex-direction__row"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()">

      <div class="global-flex"
           [ngClass]="isFiringSchemaApplied ? 'global-flex-direction__column' : ''">
        <div class="global-flex global-width--80vw">
          <div
            class="full-schema-edition__current-schema__information__read-only global-flex global-flex-direction__column global-flex-1">
            <label class="global-subtitle">{{'schemas.summary-steps-duration' | translate}}</label>
            <label>{{transformDurationToString()}}</label>
          </div>

          <div
            class="full-schema-edition__current-schema__information__import global-width--50 global-padding--02-top global-padding--02-bottom global-flex-1">
            <div
              *ngIf="shouldNameBeDisplayed"
              class="template-schemas__form__general-panel__column">
              <label class="global-subtitle">
                {{'schemas.name' | translate}}
              </label>
              <input
                id="schema-name"
                class="global-input"
                formControlName="name"
                [ngClass]="nameControl.invalid ? 'global-invalid-input-box-shadow' : ''">
            </div>
            <div
              *ngIf="shouldDescriptionBeDisplayed"
              class="template-schemas__form__general-panel__column global-flex-1">
              <label class="global-subtitle">{{'schemas.template-description' | translate}}</label>
              <textarea
                class="global-input global-height--70"
                formControlName="description"
                [ngClass]="descriptionControl.invalid ? 'global-invalid-input-box-shadow' : ''"
              ></textarea>
            </div>
          </div>

          <div class="full-schema-edition__current-schema__information__import global-width--50 global-flex-1">

            <ng-container *ngIf="importTemplateFlag; then importTemplate; else importButton"></ng-container>

            <ng-template
              #importTemplate
              class="global-flex global-flex-direction__column">
              <div class="global-flex global-width--80"></div>
              <label class="global-subtitle">
                {{'schemas.select-schema-template-to-import' | translate}}
              </label>
              <p-dropdown
                *ngIf="templatesOptions"
                class="global-input global-width--fit-content"
                data-cy="import-schema-dropdown"
                [options]="templatesOptions"
                [autoDisplayFirst]="true"
                [optionLabel]="'name'"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="selectedTemplate"
              ></p-dropdown>
              <div class="global-flex global-flex-direction__row">
                <button
                  type="button"
                  class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
                  data-cy="import-button"
                  (click)="importSelectedTemplate(selectedTemplate.id)"
                >{{'common.import' | translate}}</button>
                <button
                  type="button"
                  class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
                  data-cy="cancel-button"
                  (click)="toggleImportFlag()"
                >{{'common.cancel' | translate}}
                </button>
              </div>
            </ng-template>

            <ng-template #importButton>
              <button
                type="button"
                class="button button__submit-button button__submit-button--color button__submit-button--background-color full-schema-edition__current-production-schema__information__import__import-button"
                data-cy="import-schema-template-button"
                (click)="toggleImportFlag()"
              >{{'schemas.import-schema-template-for-current-schema' | translate}}</button>
            </ng-template>
          </div>
        </div>

        <div
          *ngIf="isFiringSchemaApplied"
          class="full-schema-edition__costs-container">

          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{'production.direct-labor-rate-cost' | translate}}</label>
            <input
              class="global-input"
              formControlName="directLaborRateCost"
              [ngClass]="directLaborRateCostControl.invalid ? 'global-invalid-input-box-shadow' : ''">
          </div>
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{'production.energy-cost' | translate}}</label>
            <input
              class="global-input"
              formControlName="energyCost"
              [ngClass]="energyCostControl.invalid ? 'global-invalid-input-box-shadow' : ''">
          </div>
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{'production.fixed-overhead-cost' | translate}}</label>
            <input
              class="global-input"
              formControlName="fixedOverheadCost"
              [ngClass]="fixedOverheadCostControl.invalid ? 'global-invalid-input-box-shadow' : ''">
          </div>
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{'production.indirect-labor-rate-cost' | translate}}</label>
            <input
              class="global-input"
              formControlName="indirectLaborRateCost"
              [ngClass]="indirectLaborRateCostControl.invalid ? 'global-invalid-input-box-shadow' : ''">
          </div>
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{'production.variable-overhead-cost' | translate}}</label>
            <input
              class="global-input"
              formControlName="variableOverheadCost"
              [ngClass]="variableOverheadCostControl.invalid ? 'global-invalid-input-box-shadow' : ''">
          </div>

        </div>

      </div>

    </div>
    <div
      class="full-schema-edition__current-schema__action global-flex global-flex-direction__column global-align__items-center"
      [ngClass]="isFiringSchemaApplied ? 'global-justify__center' : ''">
      <button
        type="submit"
        class="global-button global-width--max-content"
        data-cy="submit-button"
        [disabled]="formGroup.invalid"
        [ngStyle]="{'height': isFiringSchemaApplied && !firingSchemaEditionApplied ? 'fit-content' : ''}"
        (click)="onSubmit()"
      >{{'common.submit' | translate}}</button>
      <button
        *ngIf="!isFiringSchemaApplied || firingSchemaEditionApplied"
        type="button"
        class="global-button global-width--max-content"
        data-cy="close-button"
        (click)="returnPreviousLocation()"
      >{{'common.close' | translate}}</button>
    </div>
  </div>


  <div class="full-schema-edition__header global-flex global-flex-direction__column global-justify__center">
    <h2 class="base-display__title">
      {{'schemas.full-schema-edit-mode' | translate}}
    </h2>
    <div class="full-schema-edition__header__buttons global-flex global-flex-direction__row global-justify__flex-end">

      <button
        type="button"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="clear-all-steps"
        (click)="clearAllStep()"
      >{{'schemas.clear-all-steps' | translate}}</button>
      <button
        type="button"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="create-new-step"
        [disabled]="isSomeStepOpen()"
        (click)="createNewStep()"
      >{{'schemas.add-new-step' | translate}}</button>
    </div>
  </div>

  <div class="full-schema-edition__list">
    <div
      *ngIf="currentSchema"
      class="full-schema-edition__list__record">
      <app-schema-step-list
        #schemaStepsComponent
        [currentSchema]="currentSchema"
        [closeAllStepEditionEmitter]="closeAllStepEditionEmitter"
        [indexColumnApplied]="true">
      </app-schema-step-list>
    </div>
  </div>


  <div
    *ngIf="showPlaceHolder()"
    class="production-schema__placeholder global-margin__small">
    <h3>{{'schemas.there-is-no-defined-steps' | translate}}</h3>
  </div>

</div>

