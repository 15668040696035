/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BurningSchemaStep, DryingSchemaStep, MoldingSchemaStep, PostProcessingSchemaStep, ProductionSchema, ProductionSchemaStep, ProductionSchemaTemplate} from './model-productionschema';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class BurningSchemaStepCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: BurningSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<BurningSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<BurningSchemaStep>();
    this.httpService.post('/api/burning-schema-step/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/burning-schema-step/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BurningSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<BurningSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<BurningSchemaStep>();
    this.httpService.put('/api/burning-schema-step/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class DryingSchemaStepCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: DryingSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<DryingSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DryingSchemaStep>();
    this.httpService.post('/api/drying-schema-step/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/drying-schema-step/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: DryingSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<DryingSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DryingSchemaStep>();
    this.httpService.put('/api/drying-schema-step/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MoldingSchemaStepCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: MoldingSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldingSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldingSchemaStep>();
    this.httpService.post('/api/molding-schema-step/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/molding-schema-step/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MoldingSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldingSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldingSchemaStep>();
    this.httpService.put('/api/molding-schema-step/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class PostProcessingSchemaStepCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: PostProcessingSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<PostProcessingSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<PostProcessingSchemaStep>();
    this.httpService.post('/api/post-processing-schema-step/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/post-processing-schema-step/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: PostProcessingSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<PostProcessingSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<PostProcessingSchemaStep>();
    this.httpService.put('/api/post-processing-schema-step/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductionSchemaCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: ProductionSchema, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionSchema>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionSchema>();
    this.httpService.post('/api/production-schema/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/production-schema/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductionSchema>  {
   const subject = new Subject<ProductionSchema>();
    this.httpService.get('/api/production-schema/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductionSchemaForProductType(productTypeID: string): Observable<ProductionSchema>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductionSchema>();
    this.httpService.get('/api/production-schema/r/get/pt/', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductionSchema, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionSchema>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionSchema>();
    this.httpService.put('/api/production-schema/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductionSchemaStepCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: ProductionSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionSchemaStep>();
    this.httpService.post('/api/production-schema-step/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteProductionSchemaStepList(productionSchemaStepList: string[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<void>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<void>();
    this.httpService.post<void>('/api/production-schema-step/w/list-delete', productionSchemaStepList , {headers})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteSingleProductionSchemaStep(productionSchemaStepID: string): Observable<ProductionSchema>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productionSchemaStepID', value: productionSchemaStepID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductionSchema>();
    this.httpService.delete('/api/production-schema-step/w/delete', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductionSchemaStep>  {
   const subject = new Subject<ProductionSchemaStep>();
    this.httpService.get('/api/production-schema-step/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public handleProductionSchemaStepList(productionSchemaStepList: ProductionSchemaStep[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionSchemaStep[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionSchemaStep[]>();
    this.httpService.post('/api/production-schema-step/w/list', JsonScopedSerializer.stringify(productionSchemaStepList, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductionSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionSchemaStep>();
    this.httpService.put('/api/production-schema-step/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductionSchemaTemplateCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createDuplicateFromProductionSchemaTemplate(templateID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionSchemaTemplate>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'templateID', value: templateID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionSchemaTemplate>();
    this.httpService.post('/api/production-schema-template/w/create-duplicate', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: ProductionSchemaTemplate, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionSchemaTemplate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionSchemaTemplate>();
    this.httpService.post('/api/production-schema-template/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/production-schema-template/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllTemplates(): Observable<ProductionSchemaTemplate[]>  {
   const subject = new Subject<ProductionSchemaTemplate[]>();
    this.httpService.get('/api/production-schema-template/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllTemplatesWithoutSteps(): Observable<ProductionSchemaTemplate[]>  {
   const subject = new Subject<ProductionSchemaTemplate[]>();
    this.httpService.get('/api/production-schema-template/r/all-small', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getDuplicatedTemplateProductionStepList(templateID: string): Observable<ProductionSchemaStep[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'templateID', value: templateID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductionSchemaStep[]>();
    this.httpService.get('/api/production-schema-template/r/get-steps', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductionSchemaTemplate, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionSchemaTemplate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionSchemaTemplate>();
    this.httpService.put('/api/production-schema-template/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

