import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SelectItem} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotifyAboutChangeService {
  private source: Subject<SelectItem> = new Subject<SelectItem>();

  public content$ = this.source.asObservable();

  public loadData(data: SelectItem): void {
    this.source.next(data);
  }
}
