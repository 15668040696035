import {Component, OnInit} from '@angular/core';
import {ProductionSchemaTemplate} from '../../../core/sdk/model-productionschema';
import {NotificationService} from '../../services/notification.service';
import {SpinnerService} from '../../services/spinner.service';
import {TranslateService} from '../../../core/translations/translate.service';
import {BaseSubNavbarService} from '../../base-components/base-sub-navbar/base-sub-navbar.service';
import {Utility} from '../../utilities/utility';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {FiringSchemaTemplate} from '../../../core/sdk/model-firingschema';
import {SchemaService} from '../../services/schema.service';
import {Router} from '@angular/router';
import {CrossFunctionalService} from '../../services/cross-functional.service';

@Component({
  selector: 'app-schema-template-management',
  templateUrl: './schema-template-management.component.html',
  styles: []
})
export class SchemaTemplateManagementComponent implements OnInit {


  data: ProductionSchemaTemplate[] | FiringSchemaTemplate[];

  constructor(private notificationService: NotificationService,
              private spinnerService: SpinnerService,
              private translateService: TranslateService,
              private crossFunctionalService: CrossFunctionalService,
              private schemaService: SchemaService,
              private baseSubNavbarService: BaseSubNavbarService,
              private router: Router) {
    this.baseSubNavbarService.displayFilteringButtons(false);
    this.translateService.loadTranslationModule('schemas');
  }

  ngOnInit(): void {
    this.spinnerService.activateSpinner();
    this.schemaService.getAllTemplates(this.schemaService.getCurrentPathName(this.router)).subscribe(response => {
      this.data = response;
      this.spinnerService.deactivateSpinner();
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }


  createNewTemplate(): void {
    const currentLocation: string = this.router.url.split('/')[2].includes('products') ? 'production' : 'firing';
    this.editTemplate(this.schemaService.getEmptySchemaTemplate(currentLocation));
  }

  editTemplate(template: ProductionSchemaTemplate | FiringSchemaTemplate): void {
    if (this.isProductionTemplateSchema(template)) {
      this.crossFunctionalService.setGatheredData('passedProductionSchema', template);
      this.router.navigate(['bh/products/full-schema-edition']);
    } else {
      this.crossFunctionalService.setGatheredData('passedFiringSchema', template);
      this.router.navigate(['bh/production/full-schema-edition']);
    }
  }

  removeSchemaTemplate(template?: ProductionSchemaTemplate | FiringSchemaTemplate): void {
    this.schemaService.removeSchemaTemplate(template.id).subscribe(() => {
      this.notificationService.displayNotificationToast('schemas.template-removed-successfully',
        NotificationMessageType.SUCCESS);
      Utility.deleteObjectFromArray(this.data, template);
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

  cloneTemplate(template: FiringSchemaTemplate | ProductionSchemaTemplate): void {
    this.spinnerService.activateSpinner();
    this.schemaService.getCloneOfSchemaTemplate(template.id).subscribe(response => {
      this.pushTemplateToData(response);
      this.spinnerService.deactivateSpinner();
      this.notificationService.displayNotificationToast('schemas.template-was-cloned-successfully', NotificationMessageType.SUCCESS);
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

  private pushTemplateToData(response: ProductionSchemaTemplate | FiringSchemaTemplate): void {
    if (this.isProductionTemplateSchema(response)) {
      (this.data as ProductionSchemaTemplate[]).push(response as ProductionSchemaTemplate);
    } else {
      (this.data as FiringSchemaTemplate[]).push(response as FiringSchemaTemplate);
    }
  }

  isProductionTemplateSchema(template: ProductionSchemaTemplate | FiringSchemaTemplate): boolean {
    return template.id.includes('production');
  }

}
