import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableModule} from 'primeng/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {
  BankAccountCtrl,
  BankCtrl,
  ContactCtrl,
  FunctionalityCtrl,
  GlobalApplicationConfigCtrl,
  LanguageCtrl,
  LoginCtrl,
  PersonCtrl,
  PersonJournalEntryCtrl,
  ProcessStepCtrl,
  ProductionBookedProductCtrl,
  ProductionBookEntryCtrl, ProductionCycleHistoryCtrl,
  ProductionHomeOverviewCtrl,
  ProductionLogCtrl, ProductionPlanningCtrl,
  SecurityCtrl,
  StaticTranslationCtrl,
  StoringUnitInstanceCtrl,
  StoringUnitTypeCtrl,
  ThemeCtrl,
  VersionCtrl,
  WorkCalendarCtrl,
  WorkloadCtrl
} from './sdk/bighero-controllers';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {NewPasswordComponent} from './new-password/new-password.component';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from './translations/translate.module';
import {ButtonModule} from 'primeng/button';
import {ContextMenuModule} from 'primeng/contextmenu';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressBarModule} from 'primeng/progressbar';
import {SliderModule} from 'primeng/slider';
import {ToastModule} from 'primeng/toast';
import {BaseFormModule} from '../shared/components/base-form/base-form.module';
import {AdditionalAddressLineCtrl, AddressCtrl, CountryCtrl} from './sdk/controllers-address';
import {EnumEntityCtrl, EnumEntityTranslationCtrl} from './sdk/controllers-enumentityctrls';
import {
  ProductQualityCtrl,
  ProductTypeCtrl,
  ProductTypeGroupCtrl,
  ProductTypeRecipeCtrl,
  ProductTypeRecipeIngredientCtrl
} from './sdk/controllers-producttype';
import {
  BurningSchemaStepCtrl,
  DryingSchemaStepCtrl,
  MoldingSchemaStepCtrl,
  PostProcessingSchemaStepCtrl,
  ProductionSchemaCtrl,
  ProductionSchemaStepCtrl,
  ProductionSchemaTemplateCtrl
} from './sdk/controllers-productionschema';
import {
  OrderCtrl,
  OrderPackageCtrl,
  OrderPackageProductCtrl,
  OrderPackageRequestCtrl,
  OrderProductCtrl,
  TriangularTransactionOrderCtrl
} from './sdk/controllers-order';
import {
  DryerInstanceCtrl,
  DryerModelCtrl,
  FurnaceInstanceCtrl,
  FurnaceModelCtrl,
  MachineInstanceCtrl,
  MachineModelCtrl,
  MixingMachineInstanceCtrl,
  MixingMachineModelCtrl,
  ProductionDeviceGroupCtrl,
  ProductionDeviceInstanceCtrl,
  ProductionDeviceModelCtrl
} from './sdk/controllers-productiondevices';
import {WorkspaceGroupCtrl, WorkspaceInstanceCtrl} from './sdk/controllers-workspace';
import {BaseSubNavbarContainerComponent} from './home/base-sub-navbar-container/base-sub-navbar-container.component';
import {
  RawMaterialCtrl,
  RawMaterialMixtureCtrl,
  RawMaterialMixtureIngredientCtrl,
  RawMaterialMixtureOrderCtrl
} from './sdk/controllers-materials';
import {FiringSchemaCtrl, FiringSchemaStepCtrl, FiringSchemaTemplateCtrl} from './sdk/controllers-firingschema';
import {InvoiceCtrl} from './sdk/controllers-invoices';
import {BatchCtrl, BatchRequestCtrl} from './sdk/controllers-batch';
import {WorkerCtrl} from './sdk/controllers-roles';
import {BurningTaskCtrl, TaskCtrl, WorkerTaskCtrl} from './sdk/controllers-tasks';
import {DeclarationCtrl, DeclarationRequestCtrl, FaultyDeclarationReasonCtrl} from './sdk/controllers-declarations';
import {
  MoldGroupCtrl,
  MoldInstanceCtrl,
  MoldPartInstanceCtrl,
  MoldPartTypeCtrl,
  MoldTypeCtrl, MultiPartMoldInstanceCtrl,
  MultiPartMoldTypeCtrl
} from './sdk/controllers-mold';
import {DummyOfferProductCtrl, OfferCtrl, OfferProductCtrl} from './sdk/controllers-offer';
import {
  GroundStorageAreaCtrl,
  PickUpProductCtrl,
  ProductStoredInstanceCtrl,
  ProductStoredInstanceRequestCtrl,
  ProductStoredInstanceRequestLocationMarkCtrl,
  SectionCtrl,
  ShelfStorageAreaCtrl,
  StorageAreaCtrl,
  WarehouseCtrl, WastedProductCtrl
} from './sdk/controllers-warehouse';
import {
  InventoryCtrl,
  InventoryDeclarationCtrl,
  InventoryInProductionProductCtrl,
  InventoryProductionStepCtrl,
  InventoryStoredProductCtrl
} from './sdk/controllers-inventory';
import {DefaultPermissionCtrl} from './sdk/controllers-permission';
import {
  AdditionalAddressLineTypeCtrl,
  DeliveryTermCtrl,
  MachineModelTypeCtrl,
  ManufacturingMethodCtrl,
  PaymentTermCtrl,
  ProductShapeCtrl
} from './sdk/controllers-enumentity';
import {CurrencyCtrl, CurrencyRateCtrl} from './sdk/controllers-currency';
import {TaxRateCtrl} from './sdk/controllers-tax';
import {ProductForServiceCtrl, ServiceSchemaStepCtrl} from './sdk/controllers-productforservice';
import {CustomerCtrl, CustomerNoteCtrl} from './sdk/controllers-customer';
import {ShippingCarrierCtrl, ShippingTruckCtrl} from './sdk/controllers-shipping';


@NgModule({
  declarations: [
    LoginComponent,
    PasswordResetComponent,
    NewPasswordComponent,
    LoginComponent,
    PasswordChangeComponent,
    BaseSubNavbarContainerComponent
  ],
  imports: [
    ButtonModule,
    CalendarModule,
    CommonModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    MultiSelectModule,
    ProgressBarModule,
    SliderModule,
    TableModule,
    ToastModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
    DropdownModule,
    BaseFormModule
  ],
  exports: [
    BaseSubNavbarContainerComponent
  ],
  providers: [
    FunctionalityCtrl,
    LanguageCtrl,
    LoginCtrl,
    PersonCtrl,
    StaticTranslationCtrl,
    VersionCtrl,
    SecurityCtrl,
    ThemeCtrl,
    AddressCtrl,
    AdditionalAddressLineCtrl,
    AdditionalAddressLineTypeCtrl,
    CustomerCtrl,
    PaymentTermCtrl,
    DeliveryTermCtrl,
    ContactCtrl,
    EnumEntityTranslationCtrl,
    OfferCtrl,
    ProductTypeCtrl,
    ProductQualityCtrl,
    StoringUnitInstanceCtrl,
    StoringUnitTypeCtrl,
    ProductQualityCtrl,
    ProductionSchemaCtrl,
    ProductionSchemaStepCtrl,
    ProductionSchemaTemplateCtrl,
    OfferProductCtrl,
    GlobalApplicationConfigCtrl,
    ProductTypeGroupCtrl,
    OrderCtrl,
    OrderProductCtrl,
    OrderPackageCtrl,
    OrderPackageProductCtrl,
    MachineModelCtrl,
    MachineInstanceCtrl,
    ProductionDeviceGroupCtrl,
    PersonJournalEntryCtrl,
    WorkspaceGroupCtrl,
    WorkspaceInstanceCtrl,
    ProductionDeviceInstanceCtrl,
    FurnaceModelCtrl,
    FurnaceInstanceCtrl,
    DryerModelCtrl,
    DryerInstanceCtrl,
    MoldGroupCtrl,
    MoldTypeCtrl,
    MoldInstanceCtrl,
    FiringSchemaCtrl,
    FiringSchemaTemplateCtrl,
    FiringSchemaStepCtrl,
    BurningSchemaStepCtrl,
    MoldingSchemaStepCtrl,
    FiringSchemaTemplateCtrl,
    ProductionHomeOverviewCtrl,
    RawMaterialCtrl,
    ProductTypeRecipeCtrl,
    ProductTypeRecipeIngredientCtrl,
    RawMaterialMixtureCtrl,
    RawMaterialMixtureIngredientCtrl,
    MixingMachineModelCtrl,
    MixingMachineInstanceCtrl,
    RawMaterialMixtureOrderCtrl,
    InvoiceCtrl,
    ProductionBookedProductCtrl,
    ProductionBookEntryCtrl,
    ProcessStepCtrl,
    DryingSchemaStepCtrl,
    DeclarationCtrl,
    BatchCtrl,
    WorkerCtrl,
    TaskCtrl,
    WorkerTaskCtrl,
    WorkCalendarCtrl,
    BatchRequestCtrl,
    BurningTaskCtrl,
    FaultyDeclarationReasonCtrl,
    DeclarationRequestCtrl,
    DummyOfferProductCtrl,
    WarehouseCtrl,
    SectionCtrl,
    ProductStoredInstanceCtrl,
    StorageAreaCtrl,
    ShelfStorageAreaCtrl,
    GroundStorageAreaCtrl,
    StoringUnitTypeCtrl,
    StoringUnitInstanceCtrl,
    OrderPackageRequestCtrl,
    ProductionLogCtrl,
    InventoryCtrl,
    InventoryInProductionProductCtrl,
    InventoryStoredProductCtrl,
    InventoryDeclarationCtrl,
    InventoryProductionStepCtrl,
    PostProcessingSchemaStepCtrl,
    ProductionDeviceModelCtrl,
    WorkloadCtrl,
    DefaultPermissionCtrl,
    CurrencyCtrl,
    CurrencyRateCtrl,
    EnumEntityCtrl,
    MachineModelTypeCtrl,
    ProductShapeCtrl,
    ManufacturingMethodCtrl,
    PickUpProductCtrl,
    ProductStoredInstanceRequestCtrl,
    ProductStoredInstanceRequestLocationMarkCtrl,
    BankCtrl,
    BankAccountCtrl,
    TaxRateCtrl,
    CountryCtrl,
    MoldPartTypeCtrl,
    MultiPartMoldTypeCtrl,
    MoldPartInstanceCtrl,
    MultiPartMoldInstanceCtrl,
    WastedProductCtrl,
    ProductForServiceCtrl,
    ServiceSchemaStepCtrl,
    CustomerNoteCtrl,
    ProductionPlanningCtrl,
    TriangularTransactionOrderCtrl,
    ShippingCarrierCtrl,
    ShippingTruckCtrl,
    ProductionCycleHistoryCtrl
  ]
})
export class CoreModule {
}
