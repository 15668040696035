import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServiceSchemaStep} from '../../../../../core/sdk/model-productforservice';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../../../constance-values/constance-values';
import {Utility} from '../../../../utilities/utility';
import {WorkspaceGroup} from '../../../../../core/sdk/bighero-model';
import {ProductionSchemaStepType} from '../../../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../../../enums/translation-key-enum';
import {EnumService} from '../../../../services/enum.service';
import {ServiceSchemaStepService} from '../../../../services/service-schema-step.service';
import {CrossFunctionalService} from '../../../../services/cross-functional.service';
import {SchemaService} from '../../../../services/schema.service';
import {Router} from '@angular/router';
import {FiringSchema} from '../../../../../core/sdk/model-firingschema';

@Component({
  selector: 'app-service-schema-step-form',
  templateUrl: './service-schema-step-form.component.html',
})
export class ServiceSchemaStepFormComponent implements OnInit, AfterViewInit {

  public formGroup: FormGroup;
  public workspaceGroup: WorkspaceGroup;
  public isPostProcessingStepType = false;
  public isBurningStepType = false;
  public firingSchema: FiringSchema;
  public quantityOfMiddleProductsAfterPostProcessing: number;
  public stepTypeSelectOption = this.enumService.transformEnumOptionsForPrimeDropdown(ProductionSchemaStepType,
    TranslationKeyEnum.PRODUCTS).filter(option => !option.label.includes('Molding'));
  @Input() public serviceSchemaStep: ServiceSchemaStep;
  @Output() public closeComponentEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() public submitEmitter: EventEmitter<ServiceSchemaStep> = new EventEmitter<ServiceSchemaStep>();

  constructor(private formBuilder: FormBuilder,
              private enumService: EnumService,
              private serviceSchemaStepService: ServiceSchemaStepService,
              private crossFunctionalService: CrossFunctionalService,
              private schemaService: SchemaService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.handleFormGroupInitialization();
    this.attachSpecificFormControls();
    this.retrieveValuesFromWorkspaceGroupCross();
    this.retrieveValuesFromFiringSchemaCross();
  }

  private retrieveValuesFromFiringSchemaCross(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('firingSchemaForServiceSchemaStep')
      && this.crossFunctionalService.getGatheredData('firingSchemaForServiceSchemaStep')) {
      this.firingSchema = this.crossFunctionalService.getGatheredData('firingSchemaForServiceSchemaStep').firingSchema;
      this.firingSchemaNameControl.patchValue(this.firingSchema?.name);
      this.firingSchemaNameControl.markAsDirty();
      this.crossFunctionalService.flushData('firingSchemaForServiceSchemaStep');
    } else {
      this.firingSchema = this.serviceSchemaStepService.currentlySelectedFiringSchema;
    }
  }

  private retrieveValuesFromWorkspaceGroupCross(): void {
    if (this.crossFunctionalService.getGatheredData('workspaceGroupForServiceSchemaStep')
      ?.serviceSchemaStepId === this.serviceSchemaStep.id) {
      const workspaceGroupToBeAssigned = this.crossFunctionalService
        .getGatheredData('workspaceGroupForServiceSchemaStep').workspaceGroupToBeAssigned;
      if (workspaceGroupToBeAssigned != null) {
        this.workspaceGroupNameControl.setValue(workspaceGroupToBeAssigned.name);
        this.workspaceGroup = workspaceGroupToBeAssigned;
      }
      this.crossFunctionalService.flushData('workspaceGroupForServiceSchemaStep');
    } else {
      this.workspaceGroup = this.serviceSchemaStepService.currentlySelectedWorkspaceGroup;
    }
  }


  private handleFormGroupInitialization(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('savedFormValues')) {
      this.retrieveSavedFormGroupValues();
    } else {
      this.initFormGroup();
    }
  }

  private attachSpecificFormControls(): void {
    if (this.formGroup.controls.type.value === ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP) {
      this.attachFormControlForPostProcessingSchemaStep();
      this.removeFiringSchemaNameControl();
      this.isPostProcessingStepType = true;
      this.isBurningStepType = false;
    } else if (this.formGroup.controls.type.value === ProductionSchemaStepType.BURNING_SCHEMA_STEP) {
      this.attachFormControlsForBurningSchemaStep();
      this.removeQuantityMiddleProductsControl();
      this.isBurningStepType = true;
      this.isPostProcessingStepType = false;
    } else {
      this.isPostProcessingStepType = false;
      this.isBurningStepType = false;
      this.attachFormControlForPostProcessingSchemaStep();
      this.removeQuantityMiddleProductsControl();
    }
  }

  private retrieveSavedFormGroupValues(): void {
    this.formGroup = this.crossFunctionalService.getGatheredData('savedFormValues');
    this.crossFunctionalService.flushData('savedFormValues');
  }

  public ngAfterViewInit(): void {
    document.getElementById('assigningContainer' + this.serviceSchemaStep.id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });
  }

  public initFormGroup(): void {
    const {
      days,
      hours,
      minutes,
      seconds
    } = Utility.convertSecondsToDurationObject(this.serviceSchemaStep.stepDuration.seconds);
    this.formGroup = this.formBuilder.group({
      index: [this.serviceSchemaStep.index,
        [Validators.required, Validators.min(ConstanceValues.MIN_POSITIVE_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
      type: [this.serviceSchemaStep.type ? this.serviceSchemaStep.type : 0, [Validators.required]],
      workspaceGroupName: [this.workspaceGroup ? this.workspaceGroup?.name : this.serviceSchemaStep.workspaceGroup?.name,
        [Validators.required]],
      name: [this.serviceSchemaStep.name,
        [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      description: [this.serviceSchemaStep?.description,
        [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      days: [days, [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(999)]],
      hours: [hours, [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
      minutes: [minutes, [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
      seconds: [seconds, [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]]
    });
  }

  public onSubmitForm(): void {
    this.assignValues();
    this.serviceSchemaStepService.currentlySelectedFiringSchema = null;
    this.serviceSchemaStepService.currentlySelectedWorkspaceGroup = null;
    this.serviceSchemaStepService.tagServiceSchemaStepAsClosed(this.serviceSchemaStep.id);
    this.submitEmitter.emit(this.serviceSchemaStep);
  }

  private assignValues(): void {
    if (this.workspaceGroup) {
      this.serviceSchemaStep.workspaceGroup = this.workspaceGroup;
    }
    if (this.firingSchema) {
      this.serviceSchemaStep.firingSchema = this.firingSchema;
    }
    this.serviceSchemaStep = {...this.serviceSchemaStep, ...this.formGroup.value};
    this.serviceSchemaStep.stepDuration = {
      nanos: 0,
      seconds: this.secondsControl.value + 60 * (this.minutesControl.value
        + 60 * (this.hoursControl.value + this.daysControl.value * 24) ?? 0)
    };
  }

  public get quantityOfMiddleProductsAfterPostProcessingControl(): AbstractControl {
    return this.formGroup.get('quantityOfMiddleProductsAfterPostProcessing');
  }

  private setFormValuesBeforeAssigningWorkspaceGroup(): void {
    this.serviceSchemaStepService.currentlySelectedWorkspaceGroup = this.workspaceGroup;
    this.serviceSchemaStepService.currentlySelectedFiringSchema = this.firingSchema;
    this.crossFunctionalService.setGatheredData('savedFormValues', this.formGroup);
  }

  public assignWorkspaceGroup(): void {
    this.setFormValuesBeforeAssigningWorkspaceGroup();
    this.serviceSchemaStepService.tagOtherOpenServiceSchemaStepsAsClosed(this.serviceSchemaStep.id);
    this.crossFunctionalService.setGatheredData('assignWorkspaceGroupToServiceSchemaStep', this.serviceSchemaStep.id);
    this.crossFunctionalService.setGatheredData('workspaceGroupTypeForStep',
      this.schemaService.getWorkspaceGroupTypeForCurrentStep(this.typeControl.value));
    this.router.navigate(['bh/production/workspace-group-management']);
  }


  public assignFiringSchema(): void {
    this.serviceSchemaStepService.tagOtherOpenServiceSchemaStepsAsClosed(this.serviceSchemaStep.id);
    this.crossFunctionalService.setGatheredData('currentServiceSchemaStepForAssigningFurnaceModel',
      this.serviceSchemaStep);
    this.setFormValuesBeforeAssigningWorkspaceGroup();
    this.router.navigate(['bh/production/firing-schema-management']);
  }

  public closeComponent(): void {
    if (this.serviceSchemaStepService.isItNewStep(this.serviceSchemaStep)) {
      this.serviceSchemaStepService.removeServiceSchemaStepFromData(this.serviceSchemaStep);
    }
    this.serviceSchemaStepService.tagServiceSchemaStepAsClosed(this.serviceSchemaStep.id);
    this.closeComponentEmitter.emit();
  }

  private attachFormControlForPostProcessingSchemaStep(): void {
    this.formGroup.addControl('quantityOfMiddleProductsAfterPostProcessing',
      this.formBuilder.control(this.quantityOfMiddleProductsAfterPostProcessing ?
          this.quantityOfMiddleProductsAfterPostProcessing :
          this.serviceSchemaStep.quantityOfMiddleProductsAfterPostProcessing ?? 1,
        [Validators.required, Validators.min(ConstanceValues.MIN_POSITIVE_NUMBER),
          Validators.max(ConstanceValues.MAX_NUMBER)]));
  }

  public performActionOnTypeChange(): void {
    this.clearAssignedWorkspaceGroup();
    switch (this.typeControl.value) {
      case ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP : {
        this.attachFormControlForPostProcessingSchemaStep();
        this.quantityOfMiddleProductsAfterPostProcessingControl
          .patchValue(this.serviceSchemaStep.quantityOfMiddleProductsAfterPostProcessing);
        this.removeFiringSchemaNameControl();
        this.isPostProcessingStepType = true;
        this.isBurningStepType = false;
        break;
      }
      case ProductionSchemaStepType.BURNING_SCHEMA_STEP : {
        this.attachFormControlsForBurningSchemaStep();
        this.removeQuantityMiddleProductsControl();
        this.isBurningStepType = true;
        this.isPostProcessingStepType = false;
        break;
      }
      default: {
        this.isPostProcessingStepType = false;
        this.isBurningStepType = false;
        this.removeQuantityMiddleProductsControl();
        this.removeFiringSchemaNameControl();
      }
    }
  }

  private removeFiringSchemaNameControl(): void {
    this.formGroup.removeControl('firingSchemaName');
  }

  private removeQuantityMiddleProductsControl(): void {
    this.formGroup.removeControl('quantityOfMiddleProductsAfterPostProcessing');
  }

  private attachFormControlsForBurningSchemaStep(): void {
    this.formGroup.addControl(
      'firingSchemaName',
      this.formBuilder.control(this.firingSchema ? this.firingSchema?.name :
        this.serviceSchemaStep.firingSchema?.name, [Validators.required]));
  }

  public clearAssignedWorkspaceGroup(): void {
    this.workspaceGroupNameControl.setValue(null);
  }

  public get indexControl(): AbstractControl {
    return this.formGroup.get('index');
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  public get typeControl(): AbstractControl {
    return this.formGroup.get('type');
  }

  public get workspaceGroupNameControl(): AbstractControl {
    return this.formGroup.get('workspaceGroupName');
  }

  public get daysControl(): AbstractControl {
    return this.formGroup.get('days');
  }

  public get hoursControl(): AbstractControl {
    return this.formGroup.get('hours');
  }

  public get minutesControl(): AbstractControl {
    return this.formGroup.get('minutes');
  }

  public get secondsControl(): AbstractControl {
    return this.formGroup.get('seconds');
  }

  public get firingSchemaNameControl(): AbstractControl {
    return this.formGroup.get('firingSchemaName');
  }

}
