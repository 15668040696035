/* eslint-disable max-len */
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePrimengTableDirective} from '../../../shared/base-primeng-table/base-primeng-table.directive';
import {SpinnerService} from '../../../shared/services/spinner.service';
import {PTableControlService} from '../../../shared/services/p-table-control.service';
import {BaseSubNavbarService} from '../../../shared/base-components/base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../../shared/services/table-refresh.service';
import {MachineService} from '../../../shared/services/production/machine.service';
import {MachineModel} from '../../../core/sdk/model-productiondevices';
import {ComponentType} from '@angular/cdk/overlay';
import {MachineDetailsComponent} from './machine-details/machine-details.component';
import {TranslateService} from '../../../core/translations/translate.service';
import {EnumService} from '../../../shared/services/enum.service';
import {DeviceInstancesComponent} from '../../../shared/base-components/device-instances/device-instances.component';
import {Table} from 'primeng/table';
import {Observable} from 'rxjs';
import {CrossFunctionalService} from '../../../shared/services/cross-functional.service';
import {NotificationService} from '../../../shared/services/notification.service';
import {ExportTableDataService} from '../../../shared/services/exportTableData.service';
import {TranslationKeyEnum} from '../../../shared/enums/translation-key-enum';
import {MachineModelType} from '../../../core/sdk/model-enumentity';
import {SelectItem} from 'primeng/api';
import {Utility} from '../../../shared/utilities/utility';
import {
  WorkspaceGroupsForProductionDeviceModelComponent} from '../../../shared/base-components/workspace-groups-for-production-device-model/workspace-groups-for-production-device-model.component';
import {
  ProductionDeviceModelForAssigningWorkspaceGroup
} from '../../../shared/interfaces/cross-functional-interfaces/cross-functional-interfaces';
import {WorkspaceGroupType} from '../../../core/sdk/enums-types';

@Component({
  selector: 'app-machines-management',
  templateUrl: './machines-management.component.html',
})
export class MachinesManagementComponent extends BasePrimengTableDirective<MachineModel> implements OnInit, OnDestroy, AfterViewInit {

  public availableMachineTypeOptions: SelectItem[];
  public selectedMachineType: MachineModelType;

  public isCrossFunctionalForFurnaceModelActive = false;

  constructor(private machineService: MachineService,
              private translateService: TranslateService,
              private enumService: EnumService,
              private crossFunctionalService: CrossFunctionalService,
              private notificationService: NotificationService,
              protected spinnerService: SpinnerService,
              protected pTableControlService: PTableControlService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService,
              protected exportTableDataService: ExportTableDataService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService,
      null, exportTableDataService);
    this.assignClassName('MachineModel');
    this.assignTableName('machineModelTable');
    this.assignTableInstance(this);
    this.tableComponentType = MachinesManagementComponent;
    this.translationKey = TranslationKeyEnum.PRODUCTION;
    this.isCrossFunctionalForFurnaceModelActive = this.crossFunctionalService.isCrossFunctionalActive('savedStateForProductQualityMachinesCross');
  }

  @ViewChild('pTable') pTable: Table;

  public ngOnInit(): void {
    super.ngOnInit();
    this.loadSelectOptionsForEnums();
    this.loadFilterFlag();
  }

  public loadSelectOptionsForEnums(): void {
    this.machineService.getAllMachineModelType().subscribe(response => {
      this.availableMachineTypeOptions = Utility.getSelectItemArrayFromEnumEntityArray(response);
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public ngOnDestroy(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('wasActionPerformedForCrossOnProductQualityMachine')) {
      this.crossFunctionalService.flushData('wasActionPerformedForCrossOnProductQualityMachine');
    } else {
      this.crossFunctionalService.flushData('savedStateForProductQualityMachinesCross');
    }
    super.ngOnDestroy();
  }

  public doCount(): void {
    if (!this.shouldExportBeApplied) {
      this.machineService.countMachineModels(this.searchCriteriaForClass).subscribe(response => {
        this.pageable.count = response;
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
    }
  }

  public doLoad(): void {
    this.machineService.getMachineModels(this.searchCriteriaForClass, this.pageable.pageNumber, this.pageable.pageSize)
      .subscribe(response => {
        this.assignNewValuesToTable(response);
        this.checkIfRowsShouldBeExpanded();
        this.spinnerService.deactivateSpinner();
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public assignNewValuesToTable(data: MachineModel[]): void {
    this.shouldExportBeApplied ?
      this.exportTableDataService.setTableData(data) : this.machineService.setMachineModelData(data);
  }

  public getTableData(): MachineModel[] {
    return this.shouldExportBeApplied ?
      this.exportTableDataService.getTableData() : this.machineService.getMachineModelData();
  }

  public getTabsMap(): Map<string, ComponentType<any>> {
    this.map.set(this.translateService.translate('production.machine-details'), MachineDetailsComponent);
    this.map.set(this.translateService.translate('production.machine-instances'), DeviceInstancesComponent);
    this.map.set(this.translateService.translate('production.workspace-groups'), WorkspaceGroupsForProductionDeviceModelComponent);
    return this.map;
  }

  public bindData(machine: MachineModel): Map<string, object> {
    const dataBind: Map<string, any> = new Map<string, object>();
    const productionDeviceModelForAssigningWorkspaceGroup: ProductionDeviceModelForAssigningWorkspaceGroup = {
      productionDeviceModel: machine,
      tableName: this.tableName,
      tabName: this.translateService.translate('production.workspace-groups'),
      componentNameToNavigate: 'machines-management',
      workspaceGroupType: WorkspaceGroupType.MachineOperation
    };
    dataBind.set('currentDeviceModel', machine);
    dataBind.set('availableDeviceTypeOptions', this.availableMachineTypeOptions);
    dataBind.set('productionDeviceModelObjectForCross', productionDeviceModelForAssigningWorkspaceGroup);
    return dataBind;
  }

  public getAvailableMachineTypeOptions(): SelectItem[] {
    return this.availableMachineTypeOptions;
  }

  public getTableRef(): Table {
    return this.pTable;
  }

  public doGetSingleObject(objectId: string): Observable<MachineModel> {
    return this.machineService.getMachineModel(objectId);
  }

}
