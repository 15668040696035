import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductTypeCtrl, ProductTypeGroupCtrl} from '../../core/sdk/controllers-producttype';
import {ManufacturingCostsData, SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {ProductType, ProductTypeGroup} from '../../core/sdk/model-producttype';
import {Utility} from '../utilities/utility';
import {JsonScope} from '../../core/sdk/jsonScope';
import {DummyOfferProduct} from '../../core/sdk/model-offer';
import {ProductTypeFile} from '../../core/sdk/model-files';
import {ProductionSchema} from '../../core/sdk/model-productionschema';
import {ProductShape} from '../../core/sdk/model-enumentity';
import {ProductShapeCtrl} from '../../core/sdk/controllers-enumentity';
import {MoldType} from '../../core/sdk/model-mold';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {

  public data: ProductType[];

  constructor(private productTypeCtrl: ProductTypeCtrl,
              private productTypeGroupCtrl: ProductTypeGroupCtrl,
              private productShapeCtrl: ProductShapeCtrl) {
  }


  public getNewEmptyProductType(): ProductType {
    return {
      entityVersion: 0,
      group: null,
      length: null,
      articleNumber: null,
      deleted: false,
      moldType: null,
      withPostProcessing: false,
      height: null,
      weight: null,
      id: 'product-type/' + Utility.getUUID(),
      isDeleted: false,
      name: null,
      productionSchema: null,
      quality: null,
      shape: null,
      storingUnitInstance: null,
      timestamp: Date.now(),
      version: 0,
      width: null,
      faultyProductsCostPercentMargin: null,
    };
  }

  public setProductTypeData(data: ProductType[]): void {
    this.data = data;
  }

  public getProductTypeData(): ProductType[] {
    return this.data;
  }

  public updateSchemaForProductTypeInData(productionSchema: ProductionSchema): void {
    for (const index in this.getProductTypeData()) {
      if (this.getProductTypeData()[index].id === productionSchema.productType.id) {
        this.getProductTypeData()[index].productionSchema = productionSchema;
        break;
      }
    }
  }

  public createProductType(productType: ProductType): Observable<ProductType> {
    return this.productTypeCtrl.createObject(productType);
  }

  public updateProductType(productType: ProductType): Observable<ProductType> {
    return this.productTypeCtrl.updateObject(productType, new JsonScope(false,
      ['product-quality', 'mold-type', 'product-type-group', 'production-schema', 'storing-unit-instance']));
  }

  public updateProductTypeWithMold(productType: ProductType): Observable<ProductType> {
    return this.productTypeCtrl.updateObject(productType, new JsonScope(false,
      ['product-quality', 'product-type-group', 'production-schema', 'storing-unit-instance']));
  }

  public getProductType(productTypeId: string): Observable<ProductType> {
    return this.productTypeCtrl.getObject(Utility.getObjectId(productTypeId));
  }

  public countProductTypes(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.productTypeCtrl.countProductTypes(searchCriteria);
  }

  public getProductTypes(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<ProductType[]> {
    return this.productTypeCtrl.getProductTypes(searchCriteria, pageNumber, pageSize);
  }

  public changeAssignedMoldType(moldType: MoldType, productTypeId: string): Observable<ProductType> {
    return this.productTypeCtrl.changeAssignedMoldType(moldType, Utility.getObjectId(productTypeId));
  }

  /// MFC

  public getProductTypeMfc(productTypeId: string): Observable<number> {
    return this.productTypeCtrl.getProductTypeManufacturingCost(Utility.getObjectId(productTypeId));
  }

  public getManufacturingCostsDataForOverview(productTypeId: string): Observable<ManufacturingCostsData> {
    return this.productTypeCtrl.getManufacturingCostsDataForOverview(Utility.getObjectId(productTypeId));
  }

  /// GROUPS

  getAllProductTypeGroups(): Observable<ProductTypeGroup[]> {
    return this.productTypeGroupCtrl.findAllEnumObjects();
  }

  public createProductTypeGroup(productTypeGroupName: string): Observable<ProductTypeGroup> {
    return this.productTypeGroupCtrl.createObjectByName(productTypeGroupName);
  }

  /// FOR MOLD

  public countProductTypesForMoldType(searchCriteriaForClass: SearchCriteriaForClass, moldTypeId: string): Observable<number> {
    return this.productTypeCtrl.countProductTypesForMoldType(searchCriteriaForClass, Utility.getObjectId(moldTypeId));
  }

  public getProductTypesForMoldType(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                    moldTypeId: string): Observable<ProductType[]> {
    return this.productTypeCtrl.getProductTypesForMoldType(searchCriteriaForClass, pageNumber, pageSize, Utility.getObjectId(moldTypeId));
  }

  /// FOR DUMMY_OFFER_PRODUCT

  public createProductTypeForDummyOfferProduct(dummyOfferProduct: DummyOfferProduct, importProductionSchema: boolean,
                                               importProductTypeRecipe: boolean): Observable<ProductType> {
    return this.productTypeCtrl.createProductTypeFromDummyOfferProduct(dummyOfferProduct, importProductionSchema, importProductTypeRecipe);
  }


  /// FOR FILES

  public getProductTypeFiles(productTypeID: string, drawingFiles: boolean, pageNumber: number, pageSize: number)
    : Observable<ProductTypeFile[]> {
    return this.productTypeCtrl.getProductTypeFiles(Utility.getObjectId(productTypeID), drawingFiles, pageNumber, pageSize);
  }

  public countProductTypeFiles(productTypeID: string, drawingFiles: boolean): Observable<number> {
    return this.productTypeCtrl.countProductTypeFiles(Utility.getObjectId(productTypeID), drawingFiles);
  }

  public deleteProductTypeFile(fileID: string): Observable<void> {
    return this.productTypeCtrl.deleteProductTypeFile(Utility.getObjectId(fileID));
  }


  ///// SHAPE

  public getAllProductShapes(): Observable<ProductShape[]> {
    return this.productShapeCtrl.findAllEnumObjects();
  }

}
