<div class="custom-filtering-fields">
  <div
    *ngIf="tableNameForResetFilters"
    class="global-align--self-end">
    <button
    type="button"
    class="global-button"
    (click)="resetCustomFilters()"
    >{{(translationModule + '.reset-filters') | translate}}</button>
  </div>

  <div class="global-flex">
    <div
      *ngFor="let field of filterFields"
      class="custom-filtering-fields__field"
      [ngClass]="field === sortingField ?  'global-borders--sorting' : 'global-borders--transparent'">
      <div class="custom-filtering-fields__field__label-button">
        <label
        >{{(translationModule + '.' + field.label) | translate}}</label>
        <button
          (click)="sortBy(field)"
          class="button button__submit-button button__submit-button--background-color button__submit-button--color">
          <ng-container
            *ngIf="isSortingAscending && field === sortingField; then ascendingIcon; else descendingIcon">
          </ng-container>
          <ng-template #ascendingIcon>
            <i class="pi pi-sort-amount-down"></i>
          </ng-template>
          <ng-template #descendingIcon>
            <i class="pi pi-sort-amount-up-alt"></i>
          </ng-template>
        </button>
      </div>

      <div class="custom-filtering-fields__field__input">
        <ng-container [ngSwitch]="field.type">

          <input
            *ngSwitchCase="'string'"
            appStringLengthInputValidation
            type="text"
            placeholder="{{(translationModule + '.search-by-' + field.label) | translate }}"
            class="global-input primeng-table__filter-col"
            [(ngModel)]="field.value"
            (keydown.enter)="filterBy(field, $event)">

          <input
            *ngSwitchCase="'number'"
            appIntegerInputValidation
            type="number"
            placeholder="{{(translationModule + '.search-by-' + field.label) | translate }}"
            class="global-input primeng-table__filter-col"
            [(ngModel)]="field.value"
            (keydown.enter)="filterBy(field, $event)">

          <p-calendar
            *ngSwitchCase="'date'"
            dateFormat="yy-mm-dd"
            [showButtonBar]="true"
            [readonlyInput]="true"
            [showIcon]="true"
            [clearButtonStyleClass]="'display--false'"
            [showOnFocus]="false"
            [inputStyleClass]="'global-input'"
            [styleClass]="'global-width--100'"
            [panelStyleClass]="'global-width--200'"
            class="main-calendar"
            placeholder="{{ (translationModule +'.search-by-'+ field.label) | translate }}"
            [(ngModel)]="field.value"
            (onSelect)="filterBy(field, $event)"></p-calendar>
          <div
            *ngSwitchDefault><label
          >{{'common.filtering-for-this-type-of-object-is-not-supported' | translate}}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</div>

