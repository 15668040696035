/* eslint-disable @typescript-eslint/no-unused-vars */
import {CriteriaValue, CriteriaValueType, SearchCriteria, SearchCriteriaForClass, TableDataJoiner} from '../../core/sdk/model-dto';
import {Observable, Subscription} from 'rxjs';
import {SearchCriteriaService} from '../../core/search-criteria.service';
import {OperationSign} from '../../core/sdk/model-enums';
import {Pageable} from '../../core/pageable/pageable';
import {Utility} from '../utilities/utility';
import {Directive, OnDestroy, OnInit, Type} from '@angular/core';
import {TableRefreshService} from '../services/table-refresh.service';
import {PageEvent} from '../interfaces/interfaces';
import {ComponentType} from '@angular/cdk/overlay';
import {BaseSubNavbarService} from '../base-components/base-sub-navbar/base-sub-navbar.service';
import {SpinnerService} from '../services/spinner.service';
import {Table} from 'primeng/table';
import {PTableControlService} from '../services/p-table-control.service';
import {BasketService} from '../services/basket.service';
import {ExportTableDataService} from '../services/exportTableData.service';

@Directive()
export abstract class BasePrimengTableDirective<T> implements OnInit, OnDestroy {

  public className: string;
  public tableName: string;
  public tableComponentType: Type<any>;
  public tableInstance: any;
  displayFilteringButtons = true;
  showFilteringRow: boolean;
  map = new Map<string, ComponentType<any>>();
  pageable = new Pageable();
  ascending = true;
  searchCriteriaForClass: SearchCriteriaForClass;
  defaultSearchCriteriaList: SearchCriteria[] = [];
  data: T[] = [];
  initialSortField: string;

  public tabToBeActivate: string;

  private tableRefreshSubscription: Subscription;
  private tableExportSubscription: Subscription;
  public shouldExportBeApplied = false;
  public translatedTableName: string;
  protected translationKey: string;

  protected constructor(protected searchCriteriaService: SearchCriteriaService,
                        protected tableRefreshService?: TableRefreshService,
                        protected spinnerService?: SpinnerService,
                        protected baseSubNavbarService?: BaseSubNavbarService,
                        protected pTableControlService?: PTableControlService,
                        protected basketService?: BasketService,
                        protected exportTableDataService?: ExportTableDataService) {
  }

  public ngOnInit(): void {
    if (this.baseSubNavbarService) {
      this.tableExportSubscription = this.baseSubNavbarService.applyExport.subscribe(() => {
        this.exportTableData();
      });
    }

    this.setDefaultSearchCriteriaForClass();
    this.turnDisplayingFilterButtons();
    this.spinnerService?.activateSpinner();
    if (this.tableName && !this.shouldExportBeApplied) {
      this.tableRefreshService.createRefreshMap(this.tableName);
      if (this.tableRefreshService) {
        this.tableRefreshSubscription = this.tableRefreshService.getTableRefreshSubject(this.tableName)
          .subscribe((shouldBeRefreshed: boolean) => {
            if (shouldBeRefreshed) {
              this.searchCriteriaForClass.searchCriteriaList = Utility.getDeepClone(this.defaultSearchCriteriaList);
            }
            this.refreshTable();
          });
      }
    }

    if (!this.shouldExportBeApplied) {
      this.searchCriteriaForClass = this.searchCriteriaService.createSearchCriteriaForClass(
        this.className,
        this.initialSortField ? this.initialSortField : 'id',
        true);
      this.searchCriteriaForClass.searchCriteriaList = Utility.getDeepClone(this.defaultSearchCriteriaList);
      this.checkTabs();
    }

    if (this.basketService && this.basketService?.basketRemoveItemSubject) {
      this.basketService.basketRemoveItemSubject.subscribe(next => {
        if (next.tableName === this.tableName && this.getTableRef()) {
          this.getTableRef().expandedRowKeys = PTableControlService.removeObjectFromExpandedRows(
            next.id,
            this.getTableRef().expandedRowKeys
          );
        }
      });
    }
  }

  public resetSorting(): void {
    this.searchCriteriaForClass.isSortingStringNumeric = false;
    this.searchCriteriaForClass.sortBy = this.initialSortField ? this.initialSortField : 'id';
  }

  public ngOnDestroy(): void {
    if (this.tableRefreshSubscription) {
      this.tableRefreshSubscription.unsubscribe();
    }
    if (this.tableExportSubscription) {
      this.tableExportSubscription.unsubscribe();
    }
    if (this.exportTableDataService) {
      this.exportTableDataService.clearAllData();
    }
    if (this.basketService && this.basketService.isBasketActive(this.tableName) && this.basketService.isBasketEmpty(this.tableName)) {
      this.basketService.flushBasket(this.tableName);
    }

    this.spinnerService?.deactivateSpinner();
  }

  protected exportTableData(): void {
    if (this.exportTableDataService) {
      this.translatedTableName = this.exportTableDataService.translateExportedTableName(this.translationKey, this.tableName);
      this.exportTableDataService.setTableFunctionalityInstance(this.tableInstance);
      this.exportTableDataService.setExportedTableName(this.translatedTableName);
      this.exportTableDataService.setNumberOfAllExportedTableItems(this.pageable.count);
      this.exportTableDataService.setCurrentlyAppliedSearchCriteriaForTableFunctionality(this.searchCriteriaForClass);
      this.exportTableDataService.setClassName(this.className);
      this.exportTableDataService.export();
    }
  }

  protected checkTabs(): void {
    if (this.pTableControlService && this.pTableControlService?.isOneTimeSelectedTabActive(this.tableName)) {
      this.tabToBeActivate = this.pTableControlService?.getOneTimeSelectedTab(this.tableName);
    }
  }

  protected checkIfRowsShouldBeExpanded(): void | boolean {
    if (this.pTableControlService?.expandedRowsMap.has(this.tableName)) {
      const table = this.getTableRef();
      let tableValues = this.getTableData();
      table.expandedRowKeys = this.pTableControlService.getExpandedRows(this.tableName);
      const objectIdToExpand = Object.keys(table.expandedRowKeys)
        .find(item => table.expandedRowKeys[item] === true);
      let index: number;
      const isTableDataRootObject = tableValues && tableValues.length > 0 && 'tableDataRootObject' in tableValues[0];
      if (isTableDataRootObject) {
        for (let valueIndex = 0; tableValues.length - 1 > valueIndex; valueIndex++) {
          if ((tableValues[valueIndex] as TableDataJoiner).tableDataRootObject.id === objectIdToExpand) {
            index = valueIndex;
            break;
          }
        }
      } else {
        index = tableValues.findIndex(item => item.id in table.expandedRowKeys);
      }
      if (index >= 0) {
        let idx: number;
        if (isTableDataRootObject) {
          idx = tableValues.findIndex(a => (a as TableDataJoiner).tableDataRootObject.id in table.expandedRowKeys);
        } else {
          idx = tableValues.findIndex(a => a.id in table.expandedRowKeys);
        }
        const firstElement = tableValues[0];
        tableValues[0] = tableValues[idx];
        tableValues[idx] = firstElement;
        this.assignNewValuesToTable(tableValues);
      } else {
        this.doGetSingleObject(objectIdToExpand).subscribe(value => {
          if (isTableDataRootObject) {
            tableValues = [(value as TableDataJoiner)].concat(tableValues);
          } else {
            tableValues = [value].concat(tableValues);
          }
          this.assignNewValuesToTable(tableValues);
        });
      }
      return true;
    }
    return false;
  }

  public loadFilterFlag(): void {
    this.tableRefreshService.registeredMainFunctionalityTableNameSubject.next(this.tableName);
    this.baseSubNavbarService.showFilteringRow.subscribe(value => {
      if (this.showFilteringRow && !value) {
        this.resetSorting();
      }
      this.showFilteringRow = value;
    });
  }

  public assignClassName(className: string): void {
    this.className = className;
  }

  public assignTableName(tableName: string): void {
    this.tableName = tableName;
  }

  public assignTableInstance(instance: any): void {
    this.tableInstance = instance;
  }

  public clearTabToBeActivated(): void {
    this.tabToBeActivate = undefined;
  }

  public abstract doLoad(): void;

  public abstract doCount(): void;

  public getTableRef(): Table {
    throw new Error('Method not supported.');
  }

  public doGetSingleObject(objectId: string): Observable<T | TableDataJoiner> {
    throw new Error('Method not supported.');
  }

  public getTableData(): any[] {
    throw new Error('Method not supported.');
  }

  public assignNewValuesToTable(data: any[]): void {
    throw new Error('Method not supported.');
  }

  //eslint-disable-next-line @typescript-eslint/no-empty-function
  public setDefaultSearchCriteriaForClass(): void {
  }

  public refreshTable(): void {
    this.spinnerService.activateSpinner();
    this.doCount();
    this.doLoad();
  }

  public createCriteriaValue(value: any, valueType: CriteriaValueType): CriteriaValue {
    return {
      value,
      valueType
    };
  }

  public filterValueChange(filteredFieldName: string, inClassPropertyName: string, propertyClass: string,
                           deepFilterNestingList: string[], value: CriteriaValue, operationSign: string): void {
    this.pageable.reset();
    this.searchCriteriaForClass = this.searchCriteriaService.updateSearchCriteria(
      this.searchCriteriaForClass, filteredFieldName, inClassPropertyName, propertyClass,
      value, operationSign, false, deepFilterNestingList);
    this.refreshTable();
  }


  public searchByString(data: any, filteredFieldName: string, inClassPropertyName?: string, propertyClass?: string,
                        deepFilterNestingList?: string[]): void {
    this.pageable.reset();
    this.filterValueChange(filteredFieldName, inClassPropertyName, propertyClass, deepFilterNestingList,
      this.createCriteriaValue(data.target.value, CriteriaValueType.STRING),
      Object.keys(OperationSign).indexOf(OperationSign.LIKE.toString()).toString());
  }

  public searchByStringWithDropdown(data: any, filteredFieldName: string, inClassPropertyName: string, propertyClass?: string,
                                    deepFilterNestingList?: string[]): void {
    this.pageable.reset();
    this.filterValueChange(filteredFieldName, inClassPropertyName, propertyClass, deepFilterNestingList,
      this.createCriteriaValue(data.value[filteredFieldName], CriteriaValueType.STRING),
      Object.keys(OperationSign).indexOf(OperationSign.LIKE.toString()).toString());
  }

  public searchByEnum(data: any, filteredFieldName: string, inClassPropertyName?: string, propertyClass?: string,
                      deepFilterNestingList?: string[]): void {
    this.pageable.reset();
    this.filterValueChange(filteredFieldName, inClassPropertyName, propertyClass, deepFilterNestingList,
      this.createCriteriaValue(+data.value, CriteriaValueType.ENUM),
      Object.keys(OperationSign).indexOf(OperationSign.EQUAL.toString()).toString());
  }

  public searchByDate(data: Date, filteredFieldName: string, inClassPropertyName?: string, propertyClass?: string,
                      deepFilterNestingList?: string[]): void {
    this.pageable.reset();
    this.filterValueChange(filteredFieldName, inClassPropertyName, propertyClass, deepFilterNestingList,
      this.createCriteriaValue(data.getTime() / 1000, CriteriaValueType.DATE),
      Object.keys(OperationSign).indexOf(OperationSign.EQUAL.toString()).toString());
  }

  public searchByNumber(data: any, filteredFieldName: string, inClassPropertyName?: string, propertyClass?: string,
                        deepFilterNestingList?: string[]): void {
    this.pageable.reset();
    this.filterValueChange(filteredFieldName, inClassPropertyName, propertyClass, deepFilterNestingList,
      this.createCriteriaValue(+data.target.value, CriteriaValueType.NUMBER),
      Object.keys(OperationSign).indexOf(OperationSign.EQUAL.toString()).toString());
  }

  public paginate(event: PageEvent): void {
    this.pageable.first = event.first;
    this.pageable.pageNumber = event.first / event.rows;
    this.pageable.pageSize = event.rows;
    this.doLoad();
  }

  public sortByColumn(columnName: string, isSortingStringNumeric?: boolean, inClassPropertyName?: string, sortPropertyClass?: string,
                      deepSortNestingList?: string[]): void {
    if (this.searchCriteriaForClass.sortBy === columnName) {
      this.ascending = !this.ascending;
    } else {
      this.ascending = true;
    }
    this.searchCriteriaForClass.sortByInClassPropertyName = inClassPropertyName;
    this.searchCriteriaForClass.sortBy = columnName;
    this.searchCriteriaForClass.deepSortNestingList = deepSortNestingList;
    this.searchCriteriaForClass.isSortingStringNumeric = isSortingStringNumeric;
    this.searchCriteriaForClass.sortPropertyClass = sortPropertyClass;
    this.searchCriteriaForClass.ascending = this.ascending;
    this.pageable.pageNumber = 0;
    this.pageable.first = 0;
    this.doLoad();
  }

  public isBasketModeActive(tableName?: string): boolean {
    const wantedTableName = tableName ? tableName : this.tableName;
    return this.basketService ? this.basketService.isBasketActive(wantedTableName) : false;
  }

  public isBasketActiveAndFilled(tableName: string): boolean {
    return this.basketService ? this.basketService.isBasketActiveAndFilled(tableName) : false;
  }

  public onRowSelect(rowElement: any): void {
    if (this.isBasketModeActive()) {
      this.basketService.addOrRemoveObjectToBasket(this.tableName, rowElement.data);
    }
  }

  public unselectAllRows(): void {
    this.getTableRef().expandedRowKeys = {};
  }

  public initStaticPageable(count: number, pageSize: number, pageNumber?: number, first?: number): void {
    this.pageable.pageSize = pageSize;
    this.pageable.count = count;
    this.pageable.pageNumber = pageNumber ? pageNumber : this.pageable.count % this.pageable.pageSize === 0 ?
      this.pageable.count / this.pageable.pageSize :
      Math.floor(this.pageable.count / this.pageable.pageSize) + 1;
    this.pageable.first = first ? first : (this.pageable.pageNumber) * this.pageable.pageSize;
  }

  public isSearchCriteriaFiltersFilled(): boolean {
    return !this.searchCriteriaForClass.searchCriteriaList.isEmpty();
  }

  private turnDisplayingFilterButtons(flagValue?: boolean): void {
    if (this.baseSubNavbarService) {
      this.baseSubNavbarService.displayFilteringButtons(flagValue ? flagValue : this.displayFilteringButtons);
    }
  }
}
