<ng-container *ngIf="currentType === 'firing'; then firingStepList; else productionStepList">

</ng-container>

<ng-template #firingStepList>
  <div *ngFor="let step of getSteps()">
    <app-firing-schema-step
      [step]="$any(step)"
      [disableArrowButtons]="disabledArrowButtonsWhileCrossIsActive"
      [closeAllStepEditionEmitter]="closeAllStepEditionEmitter"></app-firing-schema-step>
  </div>
</ng-template>

<ng-template #productionStepList>
  <div *ngFor="let step of getSteps()">
    <app-production-schema-step
      [step]="$any(step)"
      [isSchemaTemplateApplied]="isTemplate(currentSchema.id)"
      [closeAllStepEditionEmitter]="closeAllStepEditionEmitter"
      [indexColumnApplied]=indexColumnApplied
      [disableArrowButtons]="disabledArrowButtonsWhileCrossIsActive">
    </app-production-schema-step>
  </div>
</ng-template>
