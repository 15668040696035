import {Injectable} from '@angular/core';
import {
  FiringSchemaCtrl,
  FiringSchemaStepCtrl,
  FiringSchemaTemplateCtrl
} from '../../../core/sdk/controllers-firingschema';
import {Observable} from 'rxjs';
import {FiringSchema, FiringSchemaStep, FiringSchemaTemplate} from '../../../core/sdk/model-firingschema';
import {Utility} from '../../utilities/utility';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {FurnaceModel} from '../../../core/sdk/model-productiondevices';
import {JsonScope} from '../../../core/sdk/jsonScope';
@Injectable({
  providedIn: 'root'
})
export class FiringSchemaService {

  private firingSchemaData: FiringSchema[];

  constructor(private firingSchemaCtrl: FiringSchemaCtrl,
              private firingSchemaTemplateCtrl: FiringSchemaTemplateCtrl,
              private firingSchemaStepCtrl: FiringSchemaStepCtrl) {
  }

  // FIRING SCHEMA

  public setFiringSchemaData(data: FiringSchema[]): void {
    this.firingSchemaData = data;
  }

  public getFiringSchemaData(): FiringSchema[] {
    return this.firingSchemaData;
  }

  public getSingleFiringSchema(objectId: string): Observable<FiringSchema> {
    return this.firingSchemaCtrl.getObject(Utility.getObjectId(objectId));
  }

  public getEmptyFiringSchema(): FiringSchema {
    return {
      id: 'firing-schema/' + Utility.getUUID(),
      directLaborRateCost: 0,
      energyCost: 0,
      fixedOverheadCost: 0,
      furnaceModelList: [],
      indirectLaborRateCost: 0,
      variableOverheadCost: 0,
      name: '',
      firingSchemaStepList: [],
      deleted: false,
      isDeleted: false,
      timestamp: undefined,
      version: 0,
      entityVersion: 0
    };
  }

  public assignFiringSchemasToFurnaceModel(firingSchemaList: FiringSchema[], furnaceModelID: string)
    : Observable<FiringSchema[]> {
    return this.firingSchemaCtrl.assignFiringSchemasToFurnaceModel(firingSchemaList, Utility.getObjectId(furnaceModelID));
  }

  public updateFiringSchema(firingSchema: FiringSchema): Observable<FiringSchema> {
    return this.firingSchemaCtrl.updateObject(firingSchema, new JsonScope(false, ['furnace-model']));
  }

  createFiringSchema(firingSchema: FiringSchema): Observable<FiringSchema> {
    return this.firingSchemaCtrl.createObject(firingSchema);
  }

  public getFiringSchemaForFurnaceModel(furnaceModelID: string): Observable<FiringSchema[]> {
    return this.firingSchemaCtrl.getFiringSchemasForFurnaceModel(Utility.getObjectId(furnaceModelID));
  }

  public deleteFiringSchema(firingSchemaID: string): Observable<void> {
    return this.firingSchemaCtrl.deleteObject(Utility.getObjectId(firingSchemaID));
  }

  public unassignFurnaceModelListFromFiringSchema(furnaceModels: FurnaceModel[], firingSchemaID: string): Observable<FiringSchema> {
    return this.firingSchemaCtrl.unassignFurnaceModelsFromFiringSchema(furnaceModels, Utility.getObjectId(firingSchemaID));
  }

  public assignFurnaceModelsToFiringSchema(furnaceModelsList: FurnaceModel[], firingSchemaID: string): Observable<FiringSchema> {
    return this.firingSchemaCtrl.assignFurnaceModelsToFiringSchema(furnaceModelsList, Utility.getObjectId(firingSchemaID));
  }


  public getFiringSchemas(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<FiringSchema[]> {
    return this.firingSchemaCtrl.getFiringSchemas(searchCriteria, pageNumber, pageSize);
  }

  public countFiringSchemas(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.firingSchemaCtrl.countFiringSchemas(searchCriteria);
  }


  // FIRING SCHEMA STEPS

  public getEmptyFiringSchemaStep(): FiringSchemaStep {
    return {
      id: 'firing-schema-step/' + Utility.getUUID(),
      index: 0,
      startTemperature: 0,
      stepDuration: Utility.convertSecondsToDurationObject(0),
      targetTemperature: 0,
      temperatureFluctuation: 0,
      description: '',
      deleted: false,
      isDeleted: false,
      timestamp: Date.now(),
      entityVersion: 0,
      version: 0
    };
  }

  updateFiringSchemaStep(firingSchemaStep: FiringSchemaStep): Observable<FiringSchemaStep> {
    return this.firingSchemaStepCtrl.updateObject(firingSchemaStep);
  }

  // FIRING SCHEMA TEMPLATES

  public getEmptyFiringSchemaTemplate(): FiringSchemaTemplate {
    return {
      id: 'firing-schema-template/' + Utility.getUUID(),
      directLaborRateCost: 0,
      energyCost: 0,
      fixedOverheadCost: 0,
      indirectLaborRateCost: 0,
      variableOverheadCost: 0,
      name: '',
      description: '',
      firingSchemaStepList: [],
      isDeleted: false,
      deleted: false,
      timestamp: Date.now(),
      entityVersion: 0,
      version: 0
    };
  }

  public getFiringSchemaTemplateWithDuplicatedStepList(firingSchemaTemplateId: string): Observable<FiringSchemaTemplate> {
    return this.firingSchemaTemplateCtrl.getFiringSchemaTemplateWithDuplicatedStepList(Utility.getObjectId(firingSchemaTemplateId));
  }

  public getAllFiringSchemaTemplatesId(): Observable<FiringSchemaTemplate[]> {
    return this.firingSchemaTemplateCtrl.getAllTemplates();
  }

  public getAllFiringSchemaTemplates(): Observable<FiringSchemaTemplate[]> {
    return this.firingSchemaTemplateCtrl.getAllTemplates();
  }

  public createFiringSchemaTemplate(firingSchemaTemplate: FiringSchemaTemplate): Observable<FiringSchemaTemplate> {
    return this.firingSchemaTemplateCtrl.createObject(firingSchemaTemplate);
  }

  public updateFiringSchemaTemplate(firingSchemaTemplate: FiringSchemaTemplate): Observable<FiringSchemaTemplate> {
    return this.firingSchemaTemplateCtrl.updateObject(firingSchemaTemplate);
  }

  public removeFiringSchemaTemplate(firingSchemaTemplateId: string): Observable<void> {
    return this.firingSchemaTemplateCtrl.deleteObject(Utility.getObjectId(Utility.getObjectId(firingSchemaTemplateId)));
  }

  public getCloneOfFiringSchemaTemplate(firingSchemaTemplateId: string): Observable<FiringSchemaTemplate> {
    return this.firingSchemaTemplateCtrl.createDuplicateFromFiringSchemaTemplate(Utility.getObjectId(firingSchemaTemplateId));
  }

  public getFiringSchemaTemplate(firingSchemaTemplateId: string): Observable<FiringSchemaTemplate> {
    return this.firingSchemaTemplateCtrl.getFiringSchemaTemplateWithDuplicatedStepList(Utility.getObjectId(firingSchemaTemplateId));
  }

}
