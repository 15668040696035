import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {NotifyAboutChangeService} from '../services/notify-about-change.service';
import {Subscription} from 'rxjs';
import {CustomFilterSortTableFieldEnum} from '../enums/enums';

@Directive({
  selector: '[appStyleTableFieldDirective]'
})
export class StyleTableFieldDirective implements OnInit, OnDestroy {

  public subscription: Subscription;
  @Input() public fieldIsShared: boolean = false;
  @Input() public elementHasChildren: boolean = false;
  @Input() public applyPointerEventsStyleForSortingTableElement: boolean = false;

  constructor(private elementRef: ElementRef,
              private notifyAboutChangeService: NotifyAboutChangeService) {
  }

  public ngOnInit(): void {
    this.setInitialStyles();
    this.subscription = this.notifyAboutChangeService.content$.subscribe(selectedItem => {
      switch (selectedItem?.value) {
        case CustomFilterSortTableFieldEnum.PRODUCT_TYPE : {
          this.setDisplayValueForElement('inline');
          if (this.applyPointerEventsStyleForSortingTableElement) {
            this.setPointerEventClassValueForTableElement('auto');
          }
          break;
        }
        case CustomFilterSortTableFieldEnum.PRODUCT_FOR_SERVICE : {
          this.fieldIsShared ? this.setDisplayValueForElement('inline') : this.setDisplayValueForElement('none');
          if (this.applyPointerEventsStyleForSortingTableElement) {
            this.fieldIsShared ? this.setPointerEventClassValueForTableElement('auto') :
              this.setPointerEventClassValueForTableElement('none');
          }
          break;
        }
        default: {
          this.setInitialStyles();
        }
      }
    });
  }

  private setInitialStyles(): void {
    if (this.applyPointerEventsStyleForSortingTableElement) {
      this.setInitialPointerEventsStyleValue();
    }
    this.setInitialDisplayValue();
  }

  private setDisplayValueForElement(displayOption: string): void {
    const element = this.elementHasChildren ? this.elementRef.nativeElement.firstElementChild : this.elementRef.nativeElement;
    element.style.display = displayOption;
  }

  public setPointerEventClassValueForTableElement(pointerEventsValue: string): void {
    this.elementRef.nativeElement.style.pointerEvents = pointerEventsValue;
  }

  private setInitialDisplayValue(): void {
    this.setDisplayValueForElement('none');
  }

  private setInitialPointerEventsStyleValue(): void {
    this.setPointerEventClassValueForTableElement('none');
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
