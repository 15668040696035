/* eslint-disable max-len */
import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InjectorClass} from './injectorClass';
import {MinutesToDurationPipe} from './pipes/minutes-to-duration.pipe';
import {TableModule} from 'primeng/table';
import {TranslateModule} from '../core/translations/translate.module';
import {BaseFormModule} from './components/base-form/base-form.module';
import {BaseSubNavbarComponent} from './base-components/base-sub-navbar/base-sub-navbar.component';
import {RouterModule} from '@angular/router';
import {
  CustomerAdditionalAddressLineComponent
} from '../components/customers/customer-additional-address-line/customer-additional-address-line.component';
import {ButtonModule} from 'primeng/button';
import {SecuredImageComponent} from './components/secured-image/secured-image.component';
import {EnumTranslationsComponent} from './enum-translations/enum-translations.component';
import {FilesTableComponent} from './components/files-table/files-table.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {SchemaStepListComponent} from './schemas/schema-step-list/schema-step-list.component';
import {
  SchemaTemplateManagementComponent
} from './schemas/schema-template-management/schema-template-management.component';
import {
  SchemaTemplateOverviewTileComponent
} from './schemas/schema-template-management/schema-template-overview-tile/schema-template-overview-tile.component';
import {
  ProductionSchemaStepFormComponent
} from './schemas/schema-step-list/production-schema-step/production-schema-step-form/production-schema-step-form.component';
import {
  ProductionSchemaStepComponent
} from './schemas/schema-step-list/production-schema-step/production-schema-step.component';
import {FiringSchemaStepComponent} from './schemas/schema-step-list/firing-schema-step/firing-schema-step.component';
import {
  FiringSchemaStepFormComponent
} from './schemas/schema-step-list/firing-schema-step/firing-schema-step-form/firing-schema-step-form.component';
import {DropdownModule} from 'primeng/dropdown';
import {BasketComponent} from './basket/basket.component';
import {
  CustomSortFilteringFieldsComponent
} from './base-components/custom-sort-filtering-fields/custom-sort-filtering-fields.component';
import {CalendarModule} from 'primeng/calendar';
import {RxStompService, StompConfig, StompService} from '@stomp/ng2-stompjs';
import {
  DynamicFunctionalityContainerComponent
} from './base-components/base-sub-navbar/dynamic-functionality-container.component';
import {
  MissingDataPlaceholderComponent
} from './components/missing-data-placeholder/missing-data-placeholder.component';
import {
  IntegerInputValidationDirective
} from './input-validation-directives/integer-input-validation-directive/integer-input-validation.directive';
import {
  StringLengthInputValidationDirective
} from './input-validation-directives/string-length-input-validation-directive/string-length-input-validation.directive';
import {ProductionLogTileComponent} from './base-components/production-log-tile/production-log-tile.component';
import {PrintModalComponent} from './printable-components/print-modal/print-modal.component';
import {
  PrintableOrderPackageLabelComponent
} from './printable-components/printable-order-package-label/printable-order-package-label.component';
import {InsertionDirective} from './printable-components/print-modal/insertion.directive';
import {
  ImageTerminalPreviewComponent
} from './printable-components/image-terminal-preview/image-terminal-preview.component';
import {LastStringAfterDotPipe} from './pipes/last-string-after-dot.pipe';
import {PricePipe} from './pipes/price.pipe';
import {PanelModule} from 'primeng/panel';
import {MobileInventoryComponent} from './base-components/mobile/mobile-inventory/mobile-inventory.component';
import {InvoiceDocumentFileComponent} from './base-components/invoice-document-file/invoice-document-file.component';
import {InvoiceActionComponent} from './base-components/invoice-document-file/invoice-action/invoice-action.component';
import {BaseDetailsDisplayComponent} from './base-components/base-details-display/base-details-display.component';
import {WorkloadTableComponent} from './workload/workload-table/workload-table.component';
import {WorkloadTilesComponent} from './workload/workload-tiles/workload-tiles.component';
import {WorkloadTileComponent} from './workload/workload-tiles/workload-tile/workload-tile.component';
import {DeviceInstancesComponent} from './base-components/device-instances/device-instances.component';
import {
  DeviceInstancesCreateFormComponent
} from './base-components/device-instances/device-instances-create-form/device-instances-create-form.component';
import {PrintBarcodeLabelComponent} from './base-components/print-barcode-label/print-barcode-label.component';
import {RockerTabNavbarComponent} from './base-components/rocker-tab-navbar/rocker-tab-navbar.component';
import {
  ProductionDeviceGroupsComponent
} from './base-components/device-instances/production-device-groups/production-device-groups.component';
import {CurrencyTableComponent} from '../components/admin/currency-management/currency-table/currency-table.component';
import {
  CountryManagementTableComponent
} from './base-components/country-management-table/country-management-table.component';
import {CountryFormComponent} from './base-components/country-management-table/country-form/country-form.component';
import {
  CountryDetailsComponent
} from './base-components/country-management-table/country-details/country-details.component';
import {TabsComponent} from './base-components/tabs/tabs/tabs.component';
import {TabComponent} from './base-components/tabs/tab/tab.component';
import {CheckboxModule} from 'primeng/checkbox';
import {
  WorkspaceGroupsForProductionDeviceModelComponent
} from './base-components/workspace-groups-for-production-device-model/workspace-groups-for-production-device-model.component';
import {
  WorkspaceInstancesForWorkspaceGroupComponent
} from './base-components/workspace-groups-for-production-device-model/workspace-instances-for-workspace-group/workspace-instances-for-workspace-group.component';
import {VirtualKeyboardDirective} from './directives/virtual-keyboard.directive';
import {
  DoubleInputValidationDirective
} from './input-validation-directives/double-input-validation-directive/DoubleInputValdiationDirective';
import {UserDecisionModalComponent} from './base-components/user-decision-modal/user-decision-modal.component';
import {
  PlaceHolderUserModalDecisionDirective
} from './directives/place-holder-user-modal-decision.directive';
import {NotificationModalComponent} from './base-components/notification-modal/notification-modal.component';
import {PlaceholderNotificationModalDirective} from './directives/placeholder-notification-modal.directive';

import { ServiceSchemaStepComponent } from './schemas/service-schema-step-list/service-schema-step/service-schema-step.component';
import { ServiceSchemaStepFormComponent } from './schemas/service-schema-step-list/service-schema-step/service-schema-step-form/service-schema-step-form.component';
import { FullServiceSchemaEditionComponent } from './schemas/full-service-schema-edition/full-service-schema-edition.component';
import {ServiceSchemaStepListComponent} from './schemas/service-schema-step-list/service-schema-step-list.component';
import {NgxBarcodeModule} from '@greatcloak/ngx-barcode';
import { ProductionPlanningMainInformationComponent } from './base-components/production-planning-main-information/production-planning-main-information.component';
import { ProductionPlanningPersonTableComponent } from './base-components/production-planning-person-table/production-planning-person-table.component';
import { ProductionPlanningWorkspaceInstanceTableComponent } from './base-components/production-planning-workspace-instance-table/production-planning-workspace-instance-table.component';
import { WorkCalendars } from './base-components/production-planning-workspace-instance-table/workers-for-workspace-instance/work-calendars';
import { MonthlyCalendarViewForWorkersComponent } from './base-components/production-planning-workspace-instance-table/workers-for-workspace-instance/monthly-calendar-view-for-workers/monthly-calendar-view-for-workers.component';
import {AssignCustomerTableComponent} from './base-components/assign-customer-table/assign-customer-table.component';
import { FiringSchemaStepsTileComponent } from './base-components/firing-schema-steps-tile/firing-schema-steps-tile.component';
import { CustomExpandPanelComponent } from './base-components/custom-expand-panel/custom-expand-panel.component';
import {ProductStoringUnitComponent} from './base-components/product-storing-unit/product-storing-unit.component';
import {
  StoringUnitTypeTableComponent
} from './base-components/product-storing-unit/storing-unit-type-table/storing-unit-type-table.component';
import {
  StoringUnitFormComponent
} from './base-components/product-storing-unit/storing-unit-form/storing-unit-form.component';
import {
  ProductStoringUnitCreateComponent
} from './base-components/product-storing-unit/product-storing-unit-create/product-storing-unit-create.component';
import {
  ProductStoringUnitEditComponent
} from './base-components/product-storing-unit/product-storing-unit-edit/product-storing-unit-edit.component';
import {
  ProductFilteringOptionsComponent
} from './base-components/product-filtering-options/product-filtering-options.component';
import {CustomFilterFieldTableDirective} from './directives/custom-filter-field-table.directive';
import {StyleTableFieldDirective} from './directives/style-table-field.directive';
import {CustomSortFieldTableDirective} from './directives/custom-sort-field-table.directive';
import {ResetSearchCriteriaTableFieldDirective} from './directives/reset-search-criteria-table-field.directive';
import {PercentOfFaultyPiecesPipe} from './pipes/percent-of-faulty-pieces.pipe';
import { OfferLockReasonComponent } from './base-components/offer-lock-reason/offer-lock-reason.component';

@NgModule({
  declarations: [
    MinutesToDurationPipe,
    BaseSubNavbarComponent,
    CustomerAdditionalAddressLineComponent,
    SecuredImageComponent,
    EnumTranslationsComponent,
    FilesTableComponent,
    SpinnerComponent,
    SchemaStepListComponent,
    SchemaTemplateManagementComponent,
    SchemaTemplateOverviewTileComponent,
    ProductionSchemaStepFormComponent,
    ProductionSchemaStepComponent,
    FiringSchemaStepComponent,
    FiringSchemaStepFormComponent,
    BasketComponent,
    CustomSortFilteringFieldsComponent,
    DynamicFunctionalityContainerComponent,
    MissingDataPlaceholderComponent,
    IntegerInputValidationDirective,
    StringLengthInputValidationDirective,
    ProductionLogTileComponent,
    PrintModalComponent,
    PrintableOrderPackageLabelComponent,
    InsertionDirective,
    ImageTerminalPreviewComponent,
    LastStringAfterDotPipe,
    PricePipe,
    MobileInventoryComponent,
    InvoiceDocumentFileComponent,
    InvoiceActionComponent,
    BaseDetailsDisplayComponent,
    WorkloadTableComponent,
    WorkloadTilesComponent,
    WorkloadTileComponent,
    DeviceInstancesComponent,
    DeviceInstancesCreateFormComponent,
    PrintBarcodeLabelComponent,
    RockerTabNavbarComponent,
    ProductionDeviceGroupsComponent,
    CurrencyTableComponent,
    CountryManagementTableComponent,
    CountryFormComponent,
    CountryDetailsComponent,
    TabsComponent,
    TabComponent,
    WorkspaceGroupsForProductionDeviceModelComponent,
    WorkspaceInstancesForWorkspaceGroupComponent,
    VirtualKeyboardDirective,
    DoubleInputValidationDirective,
    UserDecisionModalComponent,
    PlaceHolderUserModalDecisionDirective,
    NotificationModalComponent,
    PlaceholderNotificationModalDirective,
    ServiceSchemaStepComponent,
    ServiceSchemaStepFormComponent,
    FullServiceSchemaEditionComponent,
    ServiceSchemaStepListComponent,
    ProductionPlanningMainInformationComponent,
    ProductionPlanningPersonTableComponent,
    ProductionPlanningWorkspaceInstanceTableComponent,
    WorkCalendars,
    MonthlyCalendarViewForWorkersComponent,
    AssignCustomerTableComponent,
    FiringSchemaStepsTileComponent,
    CustomExpandPanelComponent,
    ProductStoringUnitComponent,
    StoringUnitTypeTableComponent,
    StoringUnitFormComponent,
    ProductStoringUnitCreateComponent,
    ProductStoringUnitEditComponent,
    ProductFilteringOptionsComponent,
    StyleTableFieldDirective,
    CustomFilterFieldTableDirective,
    CustomSortFieldTableDirective,
    ResetSearchCriteriaTableFieldDirective,
    PercentOfFaultyPiecesPipe,
    OfferLockReasonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    TableModule,
    BaseFormModule,
    RouterModule,
    ButtonModule,
    DropdownModule,
    CalendarModule,
    PanelModule,
    NgxBarcodeModule,
    CheckboxModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    MinutesToDurationPipe,
    TranslateModule,
    ReactiveFormsModule,
    TableModule,
    BaseSubNavbarComponent,
    CustomerAdditionalAddressLineComponent,
    EnumTranslationsComponent,
    SecuredImageComponent,
    FilesTableComponent,
    SchemaStepListComponent,
    SchemaTemplateManagementComponent,
    SchemaTemplateOverviewTileComponent,
    ProductionSchemaStepFormComponent,
    ProductionSchemaStepComponent,
    FiringSchemaStepComponent,
    FiringSchemaStepFormComponent,
    BasketComponent,
    CustomSortFilteringFieldsComponent,
    MissingDataPlaceholderComponent,
    IntegerInputValidationDirective,
    StringLengthInputValidationDirective,
    ProductionLogTileComponent,
    PrintModalComponent,
    InsertionDirective,
    ImageTerminalPreviewComponent,
    LastStringAfterDotPipe,
    PricePipe,
    MobileInventoryComponent,
    InvoiceDocumentFileComponent,
    BaseDetailsDisplayComponent,
    WorkloadTilesComponent,
    WorkloadTableComponent,
    DeviceInstancesComponent,
    PrintBarcodeLabelComponent,
    RockerTabNavbarComponent,
    CurrencyTableComponent,
    CountryManagementTableComponent,
    TabsComponent,
    TabComponent,
    VirtualKeyboardDirective,
    DoubleInputValidationDirective,
    PlaceHolderUserModalDecisionDirective,
    PlaceholderNotificationModalDirective,
    ServiceSchemaStepComponent,
    FullServiceSchemaEditionComponent,
    ServiceSchemaStepListComponent,
    ProductionPlanningMainInformationComponent,
    ProductionPlanningPersonTableComponent,
    ProductionPlanningWorkspaceInstanceTableComponent,
    MonthlyCalendarViewForWorkersComponent,
    AssignCustomerTableComponent,
    FiringSchemaStepsTileComponent,
    CustomExpandPanelComponent,
    ProductStoringUnitComponent,
    StoringUnitTypeTableComponent,
    StoringUnitFormComponent,
    ProductStoringUnitCreateComponent,
    ProductStoringUnitEditComponent,
    ProductFilteringOptionsComponent,
    StyleTableFieldDirective,
    CustomFilterFieldTableDirective,
    CustomSortFieldTableDirective,
    ResetSearchCriteriaTableFieldDirective,
    PercentOfFaultyPiecesPipe,
    OfferLockReasonComponent
  ],
  providers: [
    RxStompService,
    StompService, {
      provide: StompConfig
    },
  ],
})
export class SharedModule {
  constructor(public injector: Injector) {
    InjectorClass.injector = injector;
  }
}
