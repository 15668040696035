import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {CustomFilterSortTableFieldEnum} from '../../enums/enums';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';
import {EnumService} from '../../services/enum.service';
import {NotifyAboutChangeService} from '../../services/notify-about-change.service';

@Component({
  selector: 'app-product-filtering-options',
  templateUrl: './product-filtering-options.component.html',
})
export class ProductFilteringOptionsComponent implements OnInit, OnDestroy {

  public availableFilteringOptions: SelectItem[];
  private enumService = inject(EnumService);

  @Output() public selectedOptionEmitter: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();

  constructor(private notifyAboutChangeService: NotifyAboutChangeService) {
  }

  public ngOnInit(): void {
    this.availableFilteringOptions = this.enumService.transformEnumOptionsForPrimeDropdown(CustomFilterSortTableFieldEnum,
      TranslationKeyEnum.PRODUCTION);
  }

  public getAvailableFilteringOptions(): SelectItem[] {
    return this.availableFilteringOptions;
  }

  public handleFilteringOptionChange(data: any): void {
    this.selectedOptionEmitter.emit(data.value);
    this.notifyAboutChangeService.loadData(data.value);
  }

  public  ngOnDestroy(): void {
    this.notifyAboutChangeService.loadData(null);
  }


}
