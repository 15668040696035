import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../../constance-values/constance-values';
import {SpinnerService} from '../../../services/spinner.service';
import {NotificationService} from '../../../services/notification.service';
import {ServiceSchemaStepService} from '../../../services/service-schema-step.service';
import {ServiceSchemaStep} from '../../../../core/sdk/model-productforservice';
import {Utility} from '../../../utilities/utility';
import {TranslateService} from '../../../../core/translations/translate.service';
import {EnumService} from '../../../services/enum.service';
import {ProductionSchemaStepType} from '../../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../../enums/translation-key-enum';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-service-schema-step',
  templateUrl: './service-schema-step.component.html',
})
export class ServiceSchemaStepComponent implements OnInit {

  public editServiceSchemaStepFlag = false;
  public showServiceSchemaStepFormFlag = false;
  public formGroup: FormGroup;
  public isItNewStep = false;
  public quantityOfMiddleProductsAfterPostProcessing: number;
  @Input() public indexColumnApplied = false;
  @Input() public currentServiceSchemaStep: ServiceSchemaStep;

  constructor(private spinnerService: SpinnerService,
              private notificationService: NotificationService,
              private serviceSchemaStepService: ServiceSchemaStepService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private enumService: EnumService) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
    if (this.serviceSchemaStepService.isStepOpen(this.currentServiceSchemaStep.id)) {
      this.showServiceSchemaStepFormFlag = true;
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.currentServiceSchemaStep.name ?? '', [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      description: [this.currentServiceSchemaStep.description ?? '', [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]]
    });
  }


  public isStepOfTypePostProcessing(): boolean {
    return this.currentServiceSchemaStep.type === ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP;
  }

  public changeIndexHigher(): void {
    this.serviceSchemaStepService.changeIndexHigher(this.currentServiceSchemaStep, this.notificationService);
  }

  public changeIndexLower(): void {
    this.serviceSchemaStepService.changeIndexLower(this.currentServiceSchemaStep, this.notificationService);
  }

  private getServiceSchemaStepIndex(): number {
    return this.serviceSchemaStepService.getServiceSchemaData().findIndex(item => item.id === this.currentServiceSchemaStep.id);
  }

  public updateServiceSchemaStep(): void {
    this.currentServiceSchemaStep = {...this.currentServiceSchemaStep, ...this.formGroup.value};
    this.spinnerService.activateSpinner();
    this.serviceSchemaStepService.updateSingleServiceSchemaStep(this.currentServiceSchemaStep)
      .subscribe({
        next: (response: ServiceSchemaStep) => {
          this.currentServiceSchemaStep = response;
          Utility.updateObjectInData(response, this.serviceSchemaStepService.getServiceSchemaData());
          this.toggleEditServiceSchemaStepFlag();
          this.notificationService.displayNotificationToast('products.service-schema-step-updated-successfully',
            NotificationMessageType.SUCCESS);
          this.spinnerService.deactivateSpinner();
        },
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  public transformSecondsToDurationObject(seconds: number): {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  } {
    return Utility.convertSecondsToDurationObject(seconds);
  }

  public transformDurationObjectToString(duration: {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

  public getServiceSchemaStepType(enumItem: number): string {
    return this.enumService.getEnumValueWithModule(enumItem, ProductionSchemaStepType, TranslationKeyEnum.PRODUCTS);
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  public toggleShowServiceSchemaStepFormFlag(): void {
    this.showServiceSchemaStepFormFlag = !this.showServiceSchemaStepFormFlag;
    this.serviceSchemaStepService.tagServiceSchemaStepAsOpen(this.currentServiceSchemaStep.id);
  }

  public toggleEditServiceSchemaStepFlag(): void {
    this.editServiceSchemaStepFlag = !this.editServiceSchemaStepFlag;
  }

  private removeServiceSchemaStepFromData(): void {
    const index = this.serviceSchemaStepService.getServiceSchemaData().findIndex(item => item.id === this.currentServiceSchemaStep.id);
    this.serviceSchemaStepService.tagAsDeleted(index);
  }

  public removeServiceSchemaStep(): void {
    if (!this.indexColumnApplied) {
      this.spinnerService.activateSpinner();
      this.serviceSchemaStepService.deleteSingleServiceSchemaStep(this.currentServiceSchemaStep.id,
        this.serviceSchemaStepService.getCurrentProductForService().id).subscribe({
        next: (response: ServiceSchemaStep[]) => {
          this.serviceSchemaStepService.setServiceSchemaData(response);
          this.notificationService.displayNotificationToast('products.service-schema-step-deleted-successfully',
            NotificationMessageType.SUCCESS);
          this.spinnerService.deactivateSpinner();
        },
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
    } else {
      this.removeServiceSchemaStepFromData();
    }
  }

  public performActionAfterFromClose(): void {
    if (this.isItNewStep) {
      const index = this.serviceSchemaStepService.getServiceSchemaData().findIndex(item => item.id === this.currentServiceSchemaStep.id);
      this.serviceSchemaStepService.getServiceSchemaData().splice(index, 1);
    }
    this.showServiceSchemaStepFormFlag = false;
  }

  public updateServiceSchemaStepInData(serviceSchemaStep: ServiceSchemaStep): void {
    Utility.updateObjectInData(serviceSchemaStep, this.serviceSchemaStepService.getServiceSchemaData());
  }
}
