import {Injectable} from '@angular/core';
import {WorkspaceGroupCtrl} from '../../../core/sdk/controllers-workspace';
import {Observable} from 'rxjs';
import {Person, WorkspaceGroup, WorkspaceInstance} from '../../../core/sdk/bighero-model';
import {CriteriaAndArguments, SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {JsonScope} from '../../../core/sdk/jsonScope';
import {Utility} from '../../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceGroupService {
  public workspaceGroupsData;
  private workspaceGroupsForDeviceModelData: WorkspaceGroup[];

  constructor(private workspaceGroupCtrl: WorkspaceGroupCtrl) {
  }

  public getNewWorkspaceGroup(): WorkspaceGroup {
    return {
      id: 'workspace-group/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      deleted: false,
      isDeleted: false,
      workerList: [],
      groupLeaderList: [],
      name: '',
      workspaceInstanceList: [],
      type: null,
      productionDeviceModel: null
    };
  }

  public createWorkspaceGroup(workspaceGroup: WorkspaceGroup): Observable<WorkspaceGroup> {
    return this.workspaceGroupCtrl.createObject(workspaceGroup, new JsonScope(false, ['person',
      'production-device-model']));
  }

  public countWorkspaceGroups(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.workspaceGroupCtrl.countWorkspaceGroups(searchCriteria);
  }

  public getWorkspaceGroups(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<WorkspaceGroup[]> {
    return this.workspaceGroupCtrl.getWorkspaceGroups(searchCriteria, pageNumber, pageSize);
  }

  public countWorkspaceGroupsWithGroupLeaders(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.workspaceGroupCtrl.countWorkspaceGroupsWithLeaders(searchCriteriaForClass);
  }

  public getWorkspaceGroupsWithGroupLeaders(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<WorkspaceGroup[]> {
    return this.workspaceGroupCtrl.getWorkspaceGroupsWithLeaders(searchCriteriaForClass, pageNumber, pageSize);
  }

  public countWorkspaceGroupsForWorker(searchCriteriaForClass: SearchCriteriaForClass, workerId: string): Observable<number> {
    return this.workspaceGroupCtrl.countWorkspaceGroupsForWorker(searchCriteriaForClass, Utility.getObjectId(workerId));
  }

  public getWorkspaceGroupsForWorker(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                     workerId: string): Observable<WorkspaceGroup[]> {
    return this.workspaceGroupCtrl.getWorkspaceGroupsForWorker(searchCriteriaForClass, pageNumber, pageSize, Utility.getObjectId(workerId));
  }

  public getWorkspaceGroup(objectId: string): Observable<WorkspaceGroup> {
    return this.workspaceGroupCtrl.getObject(Utility.getObjectId(objectId));
  }

  public setWorkspaceGroupData(data: WorkspaceGroup[]): void {
    this.workspaceGroupsData = data;
  }

  public getWorkspaceGroupData(): WorkspaceGroup[] {
    return this.workspaceGroupsData;
  }

  public updateWorkspaceGroup(workspaceGroup: WorkspaceGroup): Observable<WorkspaceGroup> {
    return this.workspaceGroupCtrl.updateObject(workspaceGroup, new JsonScope(false, ['person',
      'production-device-model', 'workspace-group-type', 'workspace-instance']));
  }

  public updateWorkersList(workspaceGroupId: string, workerList: Person[]): Observable<WorkspaceGroup> {
    return this.workspaceGroupCtrl.updateWorkersList(Utility.getObjectId(workspaceGroupId), workerList,
      new JsonScope(true, ['person']));
  }

  public updateGroupLeaderList(workspaceGroupId: string, leadersList: Person[]): Observable<WorkspaceGroup> {
    return this.workspaceGroupCtrl.updateGroupLeaderList(Utility.getObjectId(workspaceGroupId), leadersList,
      new JsonScope(true, ['person']));
  }

  public dismissWorker(workspaceGroupId: string, workerId: string): Observable<Person[]> {
    return this.workspaceGroupCtrl.dismissWorker(Utility.getObjectId(workspaceGroupId), Utility.getObjectId(workerId),
      new JsonScope(false, ['workspace-group']));
  }

  public dismissGroupLeader(workspaceGroupId: string, leaderId: string): Observable<Person[]> {
    return this.workspaceGroupCtrl.dismissGroupLeader(Utility.getObjectId(workspaceGroupId), Utility.getObjectId(leaderId),
      new JsonScope(false, ['workspace-group']));
  }

  public addWorkspaceInstanceToGroup(instance: WorkspaceInstance): void {
    for (const index in this.workspaceGroupsData) {
      if (this.workspaceGroupsData[index].id === instance.workspaceGroup.id) {
        this.workspaceGroupsData[index].workspaceInstanceList.push(instance);
        break;
      }
    }
  }

  public removeWorkspaceInstanceFromGroup(groupId: string, instanceId: string): void {
    for (const index in this.workspaceGroupsData) {
      if (this.workspaceGroupsData[index].id === groupId) {
        Utility.deleteObjectFromArrayById(this.workspaceGroupsData[index].workspaceInstanceList, instanceId);
        break;
      }
    }
  }

  public getWorkspaceGroupsForProductionDeviceModel(searchCriteria: SearchCriteriaForClass, productionDeviceModelId: string,
                                                    pageNumber: number, pageSize: number): Observable<WorkspaceGroup[]> {
    return this.workspaceGroupCtrl.getWorkspaceGroupsForProductionDeviceModel(
      searchCriteria, Utility.getObjectId(productionDeviceModelId), pageNumber, pageSize);
  }

  public countWorkspaceGroupsForProductionDeviceModel(searchCriteria: SearchCriteriaForClass, productionDeviceModelId: string)
    : Observable<number> {
    return this.workspaceGroupCtrl.countWorkspaceGroupsForProductionDeviceModel(searchCriteria,
      Utility.getObjectId(productionDeviceModelId));
  }

  public set workspaceGroupsForProductionDeviceModelData(data: WorkspaceGroup[]) {
    this.workspaceGroupsForDeviceModelData = data;
  }

  public get workspaceGroupsForProductionDeviceModelData(): WorkspaceGroup[] {
    return this.workspaceGroupsForDeviceModelData;
  }

  public unassignProductionDeviceModelFromWorkspaceGroup(workspaceGroupId: string): Observable<WorkspaceGroup> {
    return this.workspaceGroupCtrl.unassignProductionDeviceModelFromWorkspaceGroup(Utility.getObjectId(workspaceGroupId));
  }

  public getSpecificWorkspaceGroups(criteriaAndArguments: CriteriaAndArguments, pageNumber: number, pageSize: number)
    : Observable<WorkspaceGroup[]> {
    return this.workspaceGroupCtrl.getSpecificWorkspaceGroups(criteriaAndArguments, pageNumber, pageSize);
  }

  public countSpecificWorkspaceGroups(criteriaAndArguments: CriteriaAndArguments): Observable<number> {
    return this.workspaceGroupCtrl.countSpecificWorkspaceGroups(criteriaAndArguments);
  }

}
