import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProductType} from '../../../core/sdk/model-producttype';
import {
  MoldingSchemaStep,
  ProductionSchema,
  ProductionSchemaStep,
  ProductionSchemaTemplate
} from '../../../core/sdk/model-productionschema';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {SpinnerService} from '../../services/spinner.service';
import {Utility} from '../../utilities/utility';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {FiringSchema, FiringSchemaStep, FiringSchemaTemplate} from '../../../core/sdk/model-firingschema';
import {SchemaService} from '../../services/schema.service';
import {TranslateService} from '../../../core/translations/translate.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../constance-values/constance-values';
import {ProductionSchemaStepType} from '../../../core/sdk/enums-types';
import {Subscription} from 'rxjs';
import {UserDecisionModalService} from '../../services/user-decision-modal.service';
import {PlaceHolderUserModalDecisionDirective} from '../../directives/place-holder-user-modal-decision.directive';
import {ProductTypeService} from '../../services/product-type.service';
import {MoldType} from '../../../core/sdk/model-mold';
import {HttpErrorResponse} from '@angular/common/http';
import {FiringSchemaService} from '../../services/production/firing-schema.service';
import {CrossOriginLocation} from '../../interfaces/cross-functional-interfaces/cross-functional-interfaces';
import {BaseSubNavbarService} from '../../base-components/base-sub-navbar/base-sub-navbar.service';

@Component({
  selector: 'app-full-schema-edition',
  templateUrl: './full-schema-edition.component.html'
})
export class FullSchemaEditionComponent implements OnInit, OnDestroy, AfterViewInit {

  public formGroup: FormGroup;
  public importTemplateFlag = false;
  public templatesOptions: ProductionSchemaTemplate[] | FiringSchemaTemplate[];
  public selectedTemplate: ProductionSchemaTemplate | FiringSchemaTemplate;
  public shouldNameBeDisplayed = false;
  public shouldDescriptionBeDisplayed = false;
  public currentSchema: ProductionSchema | FiringSchema | FiringSchemaTemplate | ProductionSchemaTemplate;
  public closeAllStepEditionEmitter: EventEmitter<void> = new EventEmitter<void>();

  private currentSchemaOwner: ProductType;
  private totalStepsDuration = {days: 0, hours: 0, minutes: 0, seconds: 0};
  private currentStep: ProductionSchemaStep | FiringSchemaStep;
  private fullSchemaSubscription: Subscription;
  private moldingSchemaStepsWithAssignedMoldType: MoldingSchemaStep[] = [];

  private isProductionSchemaWithMoldingSteps = false;
  private isMoldTypeDifferentAmongAnyMoldingSteps = false;
  private isMoldTypeAssignedToMoldSchemaStepsTheSameAsMoldTypeAssignedToProductType = false;
  private isAnyMoldSchemaStepWithoutMoldType = false;
  private isAnyMoldSchemaStepWithMoldType = false;

  public isFiringSchemaApplied = false;
  public firingSchemaEditionApplied = false;
  private readonly integerValidators = [Validators.required, Validators.min(ConstanceValues.MIN_NUMBER),
    Validators.max(ConstanceValues.MAX_NUMBER)];

  private readonly modalTitleForAssigningMoldTypeToProductType
    = 'schemas.do-you-want-to-change-the-assigned-mold-type-to-product-type';
  private readonly modalMessageForAssigningMoldTypeToProductType
    = 'schemas.assigning-mold-type-to-product-type-content-key';

  private readonly modalTitleForAssigningMoldTypeFromProductTypeToMoldSchemaSteps
    = 'schemas.do-you-want-to-assign-mold-type-assigned-to-product-type-for-steps-that-lack-the-assigned-mold-type';
  private readonly modalMessageForAssigningMoldTypeFromProductTypeToMoldSchemaSteps
    = 'schemas.the-mold-type-assigned-to-the-molding-steps-has-to-be-the-same-as-the-mold-type-assigned-to-the-product-type';

  @ViewChild(PlaceHolderUserModalDecisionDirective, {static: false}) public modalDecisionDirective: PlaceHolderUserModalDecisionDirective;

  constructor(private router: Router,
              private notificationService: NotificationService,
              private baseSubNavbarService:  BaseSubNavbarService,
              private schemaService: SchemaService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private crossFunctionalService: CrossFunctionalService,
              private pTableControlService: PTableControlService,
              private spinnerService: SpinnerService,
              private userDecisionModalService: UserDecisionModalService,
              private productTypeService: ProductTypeService,
              private firingSchemaService: FiringSchemaService) {
    this.baseSubNavbarService.displayFilteringButtons(false);
  }

  public ngOnInit(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('passedProductionSchema')) {
      this.currentSchema = this.crossFunctionalService.getGatheredData('passedProductionSchema');
      this.initFormGroup();
      if (!this.isTemplate(this.currentSchema.id)) {
        this.currentSchemaOwner = this.crossFunctionalService.getGatheredData('passedProductType');
      } else {
        this.shouldNameBeDisplayed = true;
        this.shouldDescriptionBeDisplayed = true;
        this.checkForNameAndDescriptionValues();
      }
      if (!this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
        this.schemaService.assignSchemaSteps((this.currentSchema as ProductionSchema)?.productionSchemaStepList);
      }
    } else if (this.crossFunctionalService.isCrossFunctionalActive('passedFiringSchema')) {
      this.firingSchemaEditionApplied = true;
      this.currentSchema = this.crossFunctionalService.getGatheredData('passedFiringSchema');
      this.crossFunctionalService.flushData('passedFiringSchema');
      this.handlePreparingDataForFiringSchema();
    } else {
      this.currentSchema = this.firingSchemaService.getEmptyFiringSchema();
      this.handlePreparingDataForFiringSchema();
    }
    this.initTotalDuration();
    this.spinnerService.activateSpinner();
  }

  public ngAfterViewInit(): void {
    this.fetchTemplates();
  }

  private handlePreparingDataForFiringSchema(): void {
    this.shouldNameBeDisplayed = true;
    this.initFormGroup();
    this.nameControl.patchValue((this.currentSchema as FiringSchemaTemplate | FiringSchema).name ?? ' ');
    if (this.isTemplate(this.currentSchema.id)) {
      this.shouldDescriptionBeDisplayed = true;
      this.checkForNameAndDescriptionValues();
    }
    if (!this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
      this.schemaService.assignSchemaSteps((this.currentSchema as FiringSchema)?.firingSchemaStepList);
    }
  }

  public ngOnDestroy(): void {
    if (!this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
      this.crossFunctionalService.flushData('passedProductionSchema');
      this.crossFunctionalService.flushData('passedProductType');
      this.crossFunctionalService.flushData('nameDescriptionForTemplate');
    } else if (this.isTemplate(this.currentSchema.id)) {
      this.crossFunctionalService.setGatheredData('nameDescriptionForTemplate', {
        name: this.nameControl.value,
        description: this.descriptionControl.value
      });
    }
    if (this.crossFunctionalService.isCrossFunctionalActive('crossOriginLocation')) {
      this.crossFunctionalService.flushData('crossOriginLocation');
    }
  }

  private checkIfFiringSchemaIsApplied(): void {
    this.isFiringSchemaApplied = !this.isProductionSchema(this.currentSchema.id);
  }

  private initFormGroup(): void {
    this.checkIfFiringSchemaIsApplied();
    this.formGroup = this.formBuilder.group({});
    if (!this.isProductionSchema(this.currentSchema.id) || this.isTemplate(this.currentSchema.id)) {
      this.attachNameControl();
    }
    if (this.isTemplate(this.currentSchema.id)) {
      this.attachDescriptionControl();
    }
    if (this.isFiringSchemaApplied) {
      this.attachDirectLaborRateCostControl();
      this.attachEnergyCostControl();
      this.attachFixedOverheadCostControl();
      this.attachIndirectLaborRateCostControl();
      this.attachVariableOverheadCostControl();
    }
  }

  private attachNameControl(): void {
    if ('name' in this.currentSchema) {
      this.formGroup.addControl('name', this.formBuilder.control(this.currentSchema.name,
        [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]));
    }
  }

  private attachDescriptionControl(): void {
    if ('description' in this.currentSchema) {
      this.formGroup.addControl('description', this.formBuilder.control(this.currentSchema.description,
        [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]));
    }
  }

  private attachDirectLaborRateCostControl(): void {
    this.formGroup.addControl('directLaborRateCost', this.formBuilder.control((this.currentSchema as FiringSchema).directLaborRateCost,
      this.integerValidators));
  }

  private attachEnergyCostControl(): void {
    this.formGroup.addControl('energyCost', this.formBuilder.control((this.currentSchema as FiringSchema).energyCost,
      this.integerValidators));
  }

  private attachFixedOverheadCostControl(): void {
    this.formGroup.addControl('fixedOverheadCost', this.formBuilder.control((this.currentSchema as FiringSchema).fixedOverheadCost,
      this.integerValidators));
  }

  private attachIndirectLaborRateCostControl(): void {
    this.formGroup.addControl('indirectLaborRateCost', this.formBuilder.control((this.currentSchema as FiringSchema).indirectLaborRateCost,
      this.integerValidators));
  }

  private attachVariableOverheadCostControl(): void {
    this.formGroup.addControl('variableOverheadCost', this.formBuilder.control((this.currentSchema as FiringSchema).variableOverheadCost,
      this.integerValidators));
  }

  private checkForNameAndDescriptionValues(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('nameDescriptionForTemplate')) {
      const nameDescriptionForTemplates = this.crossFunctionalService.getGatheredData('nameDescriptionForTemplate');
      this.nameControl.patchValue(nameDescriptionForTemplates.name);
      (this.currentSchema as ProductionSchemaTemplate).name = nameDescriptionForTemplates.name;
      this.descriptionControl.patchValue(nameDescriptionForTemplates.description);
      (this.currentSchema as ProductionSchemaTemplate).description = nameDescriptionForTemplates.description;
    } else {
      this.nameControl.patchValue((this.currentSchema as ProductionSchemaTemplate).name ?? ' ');
      this.descriptionControl.patchValue((this.currentSchema as ProductionSchemaTemplate).description ?? ' ');
    }
  }

  private fetchTemplates(): void {
    this.schemaService.getAllTemplatesId(this.currentSchema.id).subscribe(templates => {
      this.templatesOptions = templates;
      this.spinnerService.deactivateSpinner();
      this.templatesOptions.sort((a, b) => Utility.compareNumbers(a.name, b.name));
      this.selectedTemplate = this.templatesOptions[0];
    });
  }

  private initTotalDuration(): void {
    this.totalStepsDuration = this.getTotalDurationOfSteps();
  }

  public returnPreviousLocation(): void {
    if (this.isTemplate(this.currentSchema.id)) {
      this.returnToTemplates();
    } else {
      this.returnToSchema();
    }
  }

  private returnToSchema(): void {
    if (this.isProductionSchema(this.currentSchema.id)) {
      this.returnToProductionSchema();
    } else {
      this.returnToFiringSchema();
    }
  }

  private returnToProductionSchema(): void {
    this.pTableControlService.pushExpandedRowToMap('productTypeTable', this.currentSchemaOwner?.id);
    this.pTableControlService.setOneTimeSelectedTab('productTypeTable', 'products.production-schema');
    this.router.navigate(['bh/products/product-type-management']);
  }

  private returnToFiringSchema(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('crossOriginLocation')) {
      const originLocation: CrossOriginLocation = this.crossFunctionalService.getGatheredData('crossOriginLocation');
      this.pTableControlService.pushExpandedRowToMap(originLocation.tableName, originLocation.objectId);
      this.pTableControlService.setOneTimeSelectedTab(originLocation.tableName,
        this.translateService.translate(originLocation.tabName));
      this.router.navigate([originLocation.navigationPath]);
    } else {
      this.pTableControlService.pushExpandedRowToMap('firingSchemaManagementTable', this.currentSchema.id);
      this.pTableControlService.setOneTimeSelectedTab('firingSchemaManagementTable',
        this.translateService.translate('production.firing-schema-details'));
      this.router.navigate(['bh/production/firing-schema-management']);
    }
    this.router.navigate(['bh/production/firing-schema-management']);
  }

  private returnToTemplates(): void {
    if (this.isProductionSchema(this.currentSchema.id)) {
      this.returnToProductionSchemaTemplates();
    } else {
      this.returnToFiringSchemaTemplates();
    }
  }

  private returnToProductionSchemaTemplates(): void {
    this.router.navigate(['bh/products/production-template-schemas']);
  }

  private returnToFiringSchemaTemplates(): void {
    this.router.navigate(['bh/production/firing-schema-templates']);
  }

  public toggleImportFlag(): void {
    this.importTemplateFlag = !this.importTemplateFlag;
  }


  public clearAllStep(): void {
    this.schemaService.clearSchemaStepsFromData();
    this.initTotalDuration();
    this.schemaService.flushAllOpenedSteps();
  }

  private getTotalDurationOfSteps(): { days: number, hours: number, minutes: number, seconds: number } {
    const seconds = this.schemaService.getAllStepsTotalDuration(this.schemaService.getSchemaSteps());
    return Utility.convertSecondsToDurationObject(seconds ?? 0);
  }

  public transformDurationToString(): string {
    return Utility.transformDurationObjectToUserFriendlyString(this.totalStepsDuration, this.translateService);
  }

  private getCurrentMoldingSteps(): MoldingSchemaStep[] {
    return this.isProductionSchema(this.currentSchema.id) ? (this.schemaService.getSchemaSteps() as ProductionSchemaStep[])
      .filter(step => step.type === ProductionSchemaStepType.MOLDING_SCHEMA_STEP) as MoldingSchemaStep[] : [];
  }

  private setValidationFlagsForProductionSchemaSteps(): void {
    if (this.isProductionSchema(this.currentSchema.id)) {
      let comparisonMoldType: MoldType;
      const moldingSchemaStepsWithDifferentMoldTypeThanProductMoldTypeList: MoldingSchemaStep[] = [];
      (this.schemaService.getSchemaSteps() as ProductionSchemaStep[]).forEach(schemaStep => {
        switch (schemaStep.type) {
          case ProductionSchemaStepType.MOLDING_SCHEMA_STEP: {
            const moldingSchemaStep = schemaStep as MoldingSchemaStep;
            this.isProductionSchemaWithMoldingSteps = true;
            if (moldingSchemaStep.moldType == null) {
              this.isAnyMoldSchemaStepWithoutMoldType = true;
            } else {
              this.isAnyMoldSchemaStepWithMoldType = true;
              this.moldingSchemaStepsWithAssignedMoldType.push(moldingSchemaStep);
              if (comparisonMoldType == null) {
                comparisonMoldType = moldingSchemaStep.moldType;
              }
              if (comparisonMoldType.id != moldingSchemaStep.moldType.id) {
                this.isMoldTypeDifferentAmongAnyMoldingSteps = true;
              }
              if (moldingSchemaStep.moldType.id !== (this.currentSchemaOwner as ProductType)?.moldType?.id) {
                moldingSchemaStepsWithDifferentMoldTypeThanProductMoldTypeList.push(moldingSchemaStep);
              }

            }
          }
        }
      });
      if (moldingSchemaStepsWithDifferentMoldTypeThanProductMoldTypeList.isEmpty()) {
        this.isMoldTypeAssignedToMoldSchemaStepsTheSameAsMoldTypeAssignedToProductType = true;
      }
    }
  }

  private isProductTypeWithoutAssignedMoldType(): boolean {
    return !this.isTemplate(this.currentSchema.id) && this.currentSchemaOwner && (this.currentSchemaOwner as ProductType).moldType == null;
  }

  private changeAssignedMoldTypeToProductType(moldType: MoldType, productTypeId: string): void {
    this.productTypeService.changeAssignedMoldType(moldType, productTypeId).subscribe({
      next: (response: ProductType) => {
        Utility.updateObjectInData(response, this.productTypeService.data);
        this.notificationService.displayNotificationToast('schemas.mold-type-assigned-successfully',
          NotificationMessageType.SUCCESS);
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private assignMoldTypeFromProductTypeToMoldingSchemaStepsWithoutMoldType(moldTypeToAssign: MoldType): void {
    const moldingSteps = this.getCurrentMoldingSteps();
    moldingSteps.forEach(moldStep => {
      moldStep.moldType = moldTypeToAssign;
    });
    (this.currentSchema as ProductionSchema).productionSchemaStepList = moldingSteps;
  }

  private submitSchema(): void {
    this.setValidationFlagsForProductionSchemaSteps();
    if (!this.isTemplate(this.currentSchema.id) && this.isMoldTypeDifferentAmongAnyMoldingSteps) {
      this.notificationService.displayNotificationToast('schemas.there-is-some-molding-schema-steps-with-different-mold-type',
        NotificationMessageType.WARNING);
      this.resetValidationFlagsValue();
    } else if (!this.isTemplate(this.currentSchema.id) && (this.currentSchemaOwner as ProductType)?.moldType == null
      && this.isAnyMoldSchemaStepWithoutMoldType && !this.isAnyMoldSchemaStepWithMoldType) {
      this.notificationService.displayNotificationToast(
        'schemas.there-is-no-mold-type-assigned-to-product-type-either-any-molding-schema-step',
        NotificationMessageType.WARNING);
      this.resetValidationFlagsValue();
    } else {
      this.fullSchemaSubscription = this.userDecisionModalService.userModalDecisionAction$.subscribe((performAction: boolean) => {
        if (performAction) {
          if ((this.isProductTypeWithoutAssignedMoldType()
              && this.isProductionSchemaWithMoldingSteps
              && !this.isMoldTypeDifferentAmongAnyMoldingSteps)
            || (this.conditionsAreMetForAssigningMoldTypeToProductTypeModal()) ||
            this.conditionsAreMetForAssigningMoldTypeToProductTypeAndMoldingSchemaStepsWithoutMoldType()) {
            this.assignMoldTypeToProductTypeAndMoldingSchemaStepsWithoutMoldType();
          } else if (this.conditionsAreMetForAssigningMoldTypeFromProductTypeToMoldSchemaStepsModal()) {
            this.assignMoldTypeFromProductTypeToMoldingSchemaStepsWithoutMoldType((this.currentSchemaOwner as ProductType).moldType);
          }
          this.handleProcessingSchema();
        } else {
          this.resetValidationFlagsValue();
        }
        this.fullSchemaSubscription.unsubscribe();
      });

      if (this.conditionsAreMetForAssigningMoldTypeToProductTypeModal() ||
        this.conditionsAreMetForAssigningMoldTypeToProductTypeAndMoldingSchemaStepsWithoutMoldType()) {
        this.openUserDecisionModal(this.modalTitleForAssigningMoldTypeToProductType,
          this.modalMessageForAssigningMoldTypeToProductType);
      } else if (this.conditionsAreMetForAssigningMoldTypeFromProductTypeToMoldSchemaStepsModal() ||
        this.conditionsAreMetForAssigningMoldTypeToProductTypeAndMoldingSchemaStepsWithoutMoldType()) {
        this.openUserDecisionModal(this.modalTitleForAssigningMoldTypeFromProductTypeToMoldSchemaSteps,
          this.modalMessageForAssigningMoldTypeFromProductTypeToMoldSchemaSteps);
      } else {
        this.userDecisionModalService.handlePerformingUserDecisionAction(true);
      }
    }
  }

  private assignMoldTypeToProductTypeAndMoldingSchemaStepsWithoutMoldType(): void {
    let moldType: MoldType;
    if (this.conditionsAreMetForAssigningMoldTypeToProductTypeAndMoldingSchemaStepsWithoutMoldType()) {
      moldType = this.moldingSchemaStepsWithAssignedMoldType[0].moldType;
    } else {
      moldType = this.getCurrentMoldingSteps()[0].moldType;
    }
    this.changeAssignedMoldTypeToProductType(moldType,
      (this.currentSchemaOwner as ProductType).id);
    this.assignMoldTypeFromProductTypeToMoldingSchemaStepsWithoutMoldType(moldType);
  }

  private resetValidationFlagsValue(): void {
    this.isProductionSchemaWithMoldingSteps = false;
    this.isMoldTypeDifferentAmongAnyMoldingSteps = false;
    this.isMoldTypeAssignedToMoldSchemaStepsTheSameAsMoldTypeAssignedToProductType = false;
    this.isAnyMoldSchemaStepWithoutMoldType = false;
    this.isAnyMoldSchemaStepWithMoldType = false;
  }

  private openUserDecisionModal(modalTitle: string, modalContent: string): void {
    this.userDecisionModalService.viewContainerReference = this.modalDecisionDirective.viewContainerRef;
    this.userDecisionModalService.openUserDecisionModal(modalTitle, modalContent);
  }


  public onSubmit(): void {
    this.submitSchema();
  }

  private conditionsAreMetForAssigningMoldTypeToProductTypeModal(): boolean {
    return !this.isTemplate(this.currentSchema.id)
      && !this.isProductTypeWithoutAssignedMoldType()
      && this.isProductionSchemaWithMoldingSteps
      && !this.isMoldTypeDifferentAmongAnyMoldingSteps
      && !this.isMoldTypeAssignedToMoldSchemaStepsTheSameAsMoldTypeAssignedToProductType;
  }

  private conditionsAreMetForAssigningMoldTypeFromProductTypeToMoldSchemaStepsModal(): boolean {
    return !this.isTemplate(this.currentSchema.id) &&
      !this.isProductTypeWithoutAssignedMoldType() &&
      this.isProductionSchemaWithMoldingSteps &&
      this.isMoldTypeAssignedToMoldSchemaStepsTheSameAsMoldTypeAssignedToProductType &&
      this.isAnyMoldSchemaStepWithoutMoldType;
  }

  private conditionsAreMetForAssigningMoldTypeToProductTypeAndMoldingSchemaStepsWithoutMoldType(): boolean {
    return !this.isTemplate(this.currentSchema.id) &&
      this.isProductTypeWithoutAssignedMoldType() &&
      this.isProductionSchemaWithMoldingSteps &&
      this.isAnyMoldSchemaStepWithMoldType &&
      this.isAnyMoldSchemaStepWithoutMoldType &&
      !this.isMoldTypeDifferentAmongAnyMoldingSteps;
  }

  private handleProcessingSchema(): void {
    this.spinnerService.activateSpinner();
    this.closeAllStepEditionEmitter.emit();
    if (this.isProductionSchema(this.currentSchema.id)) {
      (this.currentSchema as ProductionSchema).productionSchemaStepList = this.schemaService.getSchemaSteps() as ProductionSchemaStep[];
    } else {
      (this.currentSchema as FiringSchema).firingSchemaStepList = this.schemaService.getSchemaSteps() as FiringSchemaStep[];
    }
    this.schemaService.updateIndexAndPropertiesInSchemaSteps(
      this.isProductionSchema(this.currentSchema.id) && !this.isTemplate(this.currentSchema.id)
        ? this.currentSchema as ProductionSchema : null);

    if (this.isTemplate(this.currentSchema.id)) {
      (this.currentSchema as ProductionSchemaTemplate | FiringSchemaTemplate).description = this.descriptionControl.value;
      (this.currentSchema as ProductionSchemaTemplate | FiringSchemaTemplate).name = this.nameControl.value;
      this.schemaService.updateIndexAndPropertiesInSchemaSteps();
      this.schemaService.updateSchemaTemplate(this.currentSchema as ProductionSchemaTemplate | FiringSchemaTemplate).subscribe(() => {
        this.notificationService.displayNotificationToast('schemas.template-updated', NotificationMessageType.SUCCESS);
        this.returnToTemplates();
      }, (error) => {
        this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
      });
    } else {
      this.schemaService.updateSchema(this.currentSchema as ProductionSchema | FiringSchema).subscribe(() => {
        this.notificationService.displayNotificationToast('schemas.schema-updated', NotificationMessageType.SUCCESS);
        if (this.isProductionSchema(this.currentSchema.id)) {
          const deletedStepsIdList = this.schemaService.getDeletedStepIdArray();
          if (deletedStepsIdList.length > 0) {
            this.schemaService.deleteRemovedSteps(deletedStepsIdList).subscribe(() => {
              this.returnToSchema();
            }, (error) => {
              this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
            });
          } else {
            this.returnToSchema();
          }
        } else {
          this.returnToSchema();
        }
      }, (error) => {
        this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
      });
    }
  }


  public importSelectedTemplate(templateId: string): void {
    if (this.isProductionSchema(templateId)) {
      this.schemaService.importProductionSchemaTemplateSteps(templateId).subscribe((response: ProductionSchemaStep[]) => {
        response.sort((a, b) => Utility.compareNumbers(a.index, b.index));
        this.schemaService.joinStepsToData(response);
        this.notificationService.displayNotificationToast('schemas.template-imported-successfully',
          NotificationMessageType.SUCCESS);
      }, (error) => {
        this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
      });
    } else {
      this.schemaService.importFiringSchemaTemplate(templateId).subscribe({
        next: (response) => {
          response.firingSchemaStepList.sort((a, b) => Utility.compareNumbers(a.index, b.index));
          this.schemaService.joinStepsToData(response.firingSchemaStepList);
          this.assignFiringCostsToCurrentSchema(response);
        }, error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
    }
    this.toggleImportFlag();
  }

  private assignFiringCostsToCurrentSchema(schema: FiringSchema | FiringSchemaTemplate): void {
    (this.currentSchema as FiringSchema).energyCost = schema.energyCost;
    (this.currentSchema as FiringSchema).directLaborRateCost = schema.directLaborRateCost;
    (this.currentSchema as FiringSchema).indirectLaborRateCost = schema.indirectLaborRateCost;
    (this.currentSchema as FiringSchema).fixedOverheadCost = schema.fixedOverheadCost;
    (this.currentSchema as FiringSchema).variableOverheadCost = schema.variableOverheadCost;
    if (this.isTemplate(this.currentSchema.id)) {
      (this.currentSchema as FiringSchemaTemplate).description = (schema as FiringSchemaTemplate).description;
    }
    this.patchFormControlsForFiringSchema();
  }

  private patchFormControlsForFiringSchema(): void {
    this.nameControl.patchValue((this.currentSchema as FiringSchema).name);
    this.energyCostControl.patchValue((this.currentSchema as FiringSchema).energyCost);
    this.directLaborRateCostControl.patchValue((this.currentSchema as FiringSchema).directLaborRateCost);
    this.indirectLaborRateCostControl.patchValue((this.currentSchema as FiringSchema).indirectLaborRateCost);
    this.fixedOverheadCostControl.patchValue((this.currentSchema as FiringSchema).fixedOverheadCost);
    this.variableOverheadCostControl.patchValue((this.currentSchema as FiringSchema).variableOverheadCost);
  }

  public createNewStep(): void {
    this.currentStep = this.schemaService.getEmptySchemaStep(this.currentSchema.id);
    this.currentStep.index = this.schemaService.getSchemaSteps() ? this.schemaService.getSchemaSteps().length + 1 : 1;
    this.crossFunctionalService.setGatheredData('currentlyEditedStepId', this.currentStep.id);
    this.schemaService.pushNewStepToData(this.currentStep);
    this.schemaService.addToCurrentlyEditedSteps(this.currentStep.id);
  }

  public isSomeStepOpen(): boolean {
    return this.schemaService.isSomeStepOpen();
  }

  private isProductionSchema(schemaId: string): boolean {
    return schemaId.includes('production');
  }

  private isTemplate(templateId: string): boolean {
    return templateId.includes('template');
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  public get directLaborRateCostControl(): AbstractControl {
    return this.formGroup.get('directLaborRateCost');
  }

  public get energyCostControl(): AbstractControl {
    return this.formGroup.get('energyCost');
  }

  public get fixedOverheadCostControl(): AbstractControl {
    return this.formGroup.get('fixedOverheadCost');
  }

  public get indirectLaborRateCostControl(): AbstractControl {
    return this.formGroup.get('indirectLaborRateCost');
  }

  public get variableOverheadCostControl(): AbstractControl {
    return this.formGroup.get('variableOverheadCost');
  }

  public checkIfStepsAreAvailable(steps: FiringSchemaStep[] | ProductionSchemaStep[]): boolean {
    return steps.isEmpty();
  }

  public showPlaceHolder(): boolean {
    if (this.isProductionSchema(this.currentSchema.id)) {
      return this.checkIfStepsAreAvailable((this.currentSchema as ProductionSchema).productionSchemaStepList);
    } else {
      return this.checkIfStepsAreAvailable((this.currentSchema as FiringSchema).firingSchemaStepList);
    }
  }

}
