import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchCriteria} from '../../core/sdk/model-dto';
import {Subscription} from 'rxjs';
import {NotifyAboutChangeService} from '../services/notify-about-change.service';
import {TableRefreshService} from '../services/table-refresh.service';

@Directive({
  selector: '[appResetSearchCriteriaTableFieldDirective]'
})
export class ResetSearchCriteriaTableFieldDirective implements OnInit, OnDestroy {

  public subscription: Subscription;

  @Input() public tableName: string;
  @Input() public searchCriteriaList: SearchCriteria[];

  constructor(private elementRef: ElementRef,
              private notifyAboutChangeService: NotifyAboutChangeService,
              private tableRefreshService: TableRefreshService) {
  }

  public ngOnInit(): void {
    this.subscription = this.notifyAboutChangeService.content$.subscribe(() => {
      if (!this.searchCriteriaList.isEmpty()) {
        this.tableRefreshService.onTableRefresh(this.tableName, true);
      }
      this.elementRef.nativeElement.value = null;
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
