<ng-container *ngIf="stepFormFlag; then form; else display"></ng-container>

<ng-template #display>
  <div class="production-schema__record">
    <div class="production-schema__record__action-column">
      <button class="production-schema__record__action-column__arrow-up-button"
              data-cy="arrow-up-button"
              [disabled]="shouldArrowButtonsInSchemaStepBeDisable"
              (click)="changeIndexHigher()">
        <i class="pi pi-arrow-up"></i>
      </button>
      <button class="production-schema__record__action-column__arrow-down-button"
              data-cy="arrow-down-button"
              [disabled]="shouldArrowButtonsInSchemaStepBeDisable"
              (click)="changeIndexLower()">
        <i class="pi pi-arrow-down"></i>
      </button>
    </div>
    <div class="production-schema__record__step-column">
      <label
        class="production-schema__record__step-column__box-label">
        {{getEnumValue(step.temperatureFluctuation)}}
      </label>
      <ng-container
        *ngIf="step && transformSecondsToDurationObject(step?.stepDuration?.seconds) as stepDuration">
        <label class="global-subtitle global-flex-wrap">{{'production.step-duration' |translate}}</label>
        <label>{{transformDurationObjectToString(stepDuration)}}</label>
      </ng-container>
    </div>

    <div *ngIf="step.startTemperature !== null"
         class="production-schema__record__step-column">
      <label>{{'production.start-temperature' | translate}} [℃]</label>
      <p>{{step.startTemperature}}</p>
    </div>
    <div class="production-schema__record__step-column">
      <label>{{(step.startTemperature ? 'production.target-temperature' : 'production.temperature') | translate}} [℃]</label>
      <p>{{step.targetTemperature}}</p>
    </div>
    <div class="production-schema__record__step-column">
      <label>{{'production.description' | translate}}</label>
      <textarea readonly class="global-schema-step-description">{{step.description}}</textarea>
    </div>
    <div class="production-schema__record__action-column">
      <button class="production-schema__record__action-column__edit-button"
              (click)="startEditionStep()"
              data-cy="edit-button"><i class="pi pi-pencil"></i></button>

      <button class="production-schema__record__action-column__remove-button"
              (click)="removeFiringSchemaStep()"
              data-cy="remove-button"><i class="pi pi-trash"></i></button>
    </div>
  </div>
</ng-template>

<ng-template #form>
  <app-firing-schema-step-form
    (submitStepEmitter)=updateStep($event)
    [closeAllStepEditionEmitter]="closeAllStepEditionEmitter"
    [step]="step">
  </app-firing-schema-step-form>
</ng-template>
