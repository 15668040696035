import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Person, ProcessStep, WorkCalendar, WorkspaceGroup, WorkspaceInstance} from '../../../../core/sdk/bighero-model';
import {WorkerService} from '../../../services/worker.service';
import {BasePrimengTableDirective} from '../../../base-primeng-table/base-primeng-table.directive';
import {SpinnerService} from '../../../services/spinner.service';
import {PTableControlService} from '../../../services/p-table-control.service';
import {BaseSubNavbarService} from '../../base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../../core/search-criteria.service';
import {TableRefreshService} from '../../../services/table-refresh.service';
import {Utility} from '../../../utilities/utility';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../../../services/notification.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Worker} from '../../../../core/sdk/model-roles';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {WorkspaceGroupType} from '../../../../core/sdk/enums-types';

@Component({
  selector: 'app-work-calendars',
  templateUrl: './work-calendars.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class WorkCalendars extends BasePrimengTableDirective<WorkCalendar> implements OnInit, AfterViewInit, OnDestroy {

  public workerFilterField: string;
  public dateFromFilterField: number;
  public dateToFilterField: number;

  public showMonthlyCalendarViewFlag = false;
  public createWorkCalendarEntryFlag = false;
  public showAvailableWorkersTable = false;
  public showWorkspaceInstancesFlag = false;

  public todayDate: Date;
  public fromDate: Date;
  public toDate: Date;
  public worker: Worker;

  public formGroup: FormGroup;

  @Input() public workspaceInstance: WorkspaceInstance;
  @Input() public mainWorkspaceGroup: WorkspaceGroup;
  @Input() public processStep: ProcessStep;
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private workerService: WorkerService,
              private notificationService: NotificationService,
              private formBuilder: FormBuilder,
              protected spinnerService: SpinnerService,
              protected pTableControlService: PTableControlService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService);
    this.assignClassName('WorkCalendar');
    this.assignTableName('workersForWorkspaceInstanceTable');
  }

  public ngOnInit() {
    super.ngOnInit();
    this.todayDate = new Date(Date.now());
    this.fromDate = new Date(Date.now());
    this.toDate = new Date(Date.now());
    this.initFormGroup();
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public assignNewValuesToTable(data: WorkCalendar[]): void {
    this.workerService.setWorkCalendarData(data);
  }

  public getTableData(): WorkCalendar[] {
    return this.workerService.getWorkCalendarData();
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      worker: ['', Validators.required],
      fromDate: [this.fromDate, Validators.required],
      toDate: [this.toDate, Validators.required],
    });
  }

  public doCount(): void {
    this.mainWorkspaceGroup ? this.countWorkCalendarsForWorkspaceGroup() : this.countWorkCalendarsForWorkspaceInstance();
  }

  private countWorkCalendarsForWorkspaceInstance(): void {
    this.workerService.countWorkCalendarsForWorkspaceInstance(this.searchCriteriaForClass, this.workspaceInstance.id)
      .subscribe({
        next: (response: number) => this.pageable.count = response,
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  private countWorkCalendarsForWorkspaceGroup(): void {
    this.workerService.countWorkCalendarsForWorkspaceGroup(this.searchCriteriaForClass,
      this.mainWorkspaceGroup.id).subscribe({
      next: (response: number) => this.pageable.count = response
    });
  }

  private getWorkCalendarsForWorkspaceInstance(): void {
    this.workerService.getWorkCalendarsForWorkspaceInstance(this.searchCriteriaForClass, this.workspaceInstance.id,
      this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: WorkCalendar[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private getWorkCalendarsForWorkspaceGroup(): void {
    this.workerService.getWorkCalendarsForWorkspaceGroup(this.searchCriteriaForClass, Utility.getObjectId(this.mainWorkspaceGroup.id),
      this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: WorkCalendar[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public doLoad(): void {
    this.mainWorkspaceGroup ? this.getWorkCalendarsForWorkspaceGroup() : this.getWorkCalendarsForWorkspaceInstance();
  }

  public convertTimestampToStringDate(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }

  public resetFilters(): void {
    this.workerFilterField = null;
    this.dateFromFilterField = null;
    this.dateToFilterField = null;
    this.tableRefreshService.onTableRefresh(this.tableName, true);
  }

  public closeView(): void {
    this.closeViewEmitter.emit();
  }

  public toggleShowMonthlyCalendarViewFlag(): void {
    this.showMonthlyCalendarViewFlag = !this.showMonthlyCalendarViewFlag;
  }

  public toggleCreateWorkCalendarEntryFlag(): void {
    this.createWorkCalendarEntryFlag = !this.createWorkCalendarEntryFlag;
  }

  public assignWorker(): void {
    this.toggleShowAvailableWorkersTable();
  }

  public onSubmit(): void {
    this.spinnerService.activateSpinner();
    this.fromDate = this.fromDateControl.value;
    this.toDate = this.toDateControl.value;
    this.workerService.createWorkCalendarEntry(this.workerService.getNewWorkCalendarEntry(this.worker,
      this.workspaceInstance, this.mainWorkspaceGroup, this.fromDate, this.toDate)).subscribe({
      next: (response: WorkCalendar) => {
        this.workerService.getWorkCalendarData().push(response);
        this.pageable.count++;
        this.toggleCreateWorkCalendarEntryFlag();
        this.clearFormControlsValues();
        this.notificationService.displayNotificationToast('production.work-calendar-entry-created-successfully',
          NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public assignToDate(date: Date): void {
    this.toDate = date;
  }

  public assignFromDate(date: Date): void {
    this.fromDate = date;
  }

  public setChosenWorker(chosenWorker: Person): void {
    this.retrieveWorkerFromPersonObject(chosenWorker);
    this.workerControl.setValue(chosenWorker.name);
    this.toggleShowAvailableWorkersTable();
  }

  private retrieveWorkerFromPersonObject(person: Person): void {
    this.worker = (person.personRoleList.find(personRole => personRole.id.includes('worker'))) as Worker;
  }

  public onCancel(): void {
    this.toggleCreateWorkCalendarEntryFlag();
    this.clearFormControlsValues();
  }

  public toggleShowAvailableWorkersTable(): void {
    this.showAvailableWorkersTable = !this.showAvailableWorkersTable;
  }

  private get workerControl(): AbstractControl {
    return this.formGroup.get('worker');
  }

  private get fromDateControl(): AbstractControl {
    return this.formGroup.get('fromDate');
  }

  private get toDateControl(): AbstractControl {
    return this.formGroup.get('toDate');
  }

  private clearFormControlsValues(): void {
    this.workerControl.setValue(null);
    this.fromDateControl.setValue(new Date(Date.now()));
    this.toDateControl.setValue(new Date(Date.now()));
  }

  public toggleShowWorkspaceInstancesFlag(): void {
    this.showWorkspaceInstancesFlag = !this.showWorkspaceInstancesFlag;
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.workerService.clearWorkCalendarData();
  }

  public isOvenOperation(): boolean {
    return this.mainWorkspaceGroup.type === WorkspaceGroupType.OvenOperation;
  }


}
