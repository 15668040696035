import {Component, Input, OnInit} from '@angular/core';
import {ProcessStep, WorkspaceInstance} from '../../../core/sdk/bighero-model';
import {WorkspaceGroupType} from '../../../core/sdk/enums-types';

@Component({
  selector: 'app-production-planning-workspace-instance-table',
  templateUrl: './production-planning-workspace-instance-table.component.html',
})
export class ProductionPlanningWorkspaceInstanceTableComponent implements OnInit {

  public showWorkCalendarForWorkspaceInstanceFlag = false;
  public currentlyChosenWorkspaceInstance: WorkspaceInstance;

  @Input() public currentProcessStep: ProcessStep;

  public toggleShowWorkersFlag(): void {
    this.showWorkCalendarForWorkspaceInstanceFlag = !this.showWorkCalendarForWorkspaceInstanceFlag;
  }

  public enableWorkersForWorkspaceInstanceView(currentWorkspaceInstance: WorkspaceInstance): void {
    this.currentlyChosenWorkspaceInstance = currentWorkspaceInstance;
    this.toggleShowWorkersFlag();
  }

  public ngOnInit(): void {
    this.isOvenOperation();
  }

  public isOvenOperation(): boolean {
    return this.currentProcessStep.workspaceGroup.type === WorkspaceGroupType.OvenOperation;
  }

}
