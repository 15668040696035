<div class="full-schema-edition">
  <div class="full-schema-edition__current-schema schemas__list__record global-border-radius">
    <div
      class="full-schema-edition__current-schema__information global-flex global-flex-direction__row">
      <div
        class="full-schema-edition__current-schema__information__read-only global-flex global-flex-direction__column global-width--50">
        <label class="global-subtitle">{{'products.summary-steps-duration' | translate}}</label>
        <label>{{transformDurationToString()}}</label>
      </div>

      <div class="full-schema-edition__current-schema__information__import global-width--50">

        <ng-container *ngIf="importTemplateFlag; then importTemplate; else importButton"></ng-container>

        <ng-template
          #importTemplate
          class="global-flex global-flex-direction__column">
          <div class="global-flex global-width--80"></div>
          <label class="global-subtitle">
            {{'products.select-schema-template-to-import' | translate}}
          </label>
          <p-dropdown
            *ngIf="templatesOptions"
            class="global-input global-width--fit-content"
            data-cy="import-schema-dropdown"
            [options]="templatesOptions"
            [autoDisplayFirst]="true"
            [optionLabel]="'name'"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="selectedTemplate"
          ></p-dropdown>
          <div class="global-flex global-flex-direction__row">
            <button
              type="button"
              class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
              data-cy="import-button"
              (click)="importSelectedTemplate(selectedTemplate.id)"
            >{{'common.import' | translate}}</button>
            <button
              type="button"
              class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
              data-cy="cancel-button"
              (click)="toggleServiceSchemaImportFlag()"
            >{{'common.cancel' | translate}}
            </button>
          </div>
        </ng-template>

        <ng-template #importButton>
          <button
            type="button"
            class="button button__submit-button button__submit-button--color button__submit-button--background-color full-schema-edition__current-production-schema__information__import__import-button"
            data-cy="import-schema-template-button"
            (click)="toggleServiceSchemaImportFlag()"
          >{{'products.import-schema-template-for-current-schema' | translate}}</button>
        </ng-template>
      </div>
    </div>
    <div class="full-schema-edition__current-schema__action global-flex global-flex-direction__column global-align__items-center">
      <button
        type="submit"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="submit-button"
        (click)="onSubmit()"
      >{{'common.submit' | translate}}</button>
      <button
        type="button"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="close-button"
        (click)="closeComponent()"
      >{{'common.close' | translate}}</button>
    </div>
  </div>


  <div class="full-schema-edition__header global-flex global-flex-direction__column global-justify__center">
    <h2 class="base-display__title">
      {{'products.full-service-schema-steps-edit-mode' | translate}}
    </h2>
    <div class="full-schema-edition__header__buttons global-flex global-flex-direction__row global-justify__flex-end">
      <button
        type="button"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="clear-all-steps"
        (click)="clearAllStep()"
      >{{'products.clear-all-steps' | translate}}</button>
      <button
        type="button"
        class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
        data-cy="create-new-step"
        (click)="createNewStep()"
      >{{'products.add-new-step' | translate}}</button>
    </div>
  </div>
  <app-service-schema-step-list
  [indexColumnApplied]="true">
  </app-service-schema-step-list>
</div>

