/* eslint-disable space-before-function-paren */

import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../services/notification.service';
import {NotificationMessageType} from '../enums/NotificationMessageType';
import {TranslateService} from '../../core/translations/translate.service';
import * as lodash from 'lodash';
import {BaseEnumEntity} from '../../core/sdk/model-enumentity';
import {SelectItem} from 'primeng/api';
import {Router} from '@angular/router';

const uuid4 = require('uuid/v4');

/** Implementation of global extended interfaces */
String.prototype.isEmpty = function(): boolean {
  return (this.length === 0 || !this.trim());
};

String.prototype.charArray = function(): string[] {
  return (this.isEmpty()) ? [] : [...this];
};

String.prototype.characterLength = function(): number {
  return this.charArray().length;
};

String.prototype.containsUnicode = function(): boolean {
  return this.characterLength() !== this.length;
};

Array.prototype.groupBy = function <T, K>(keyGetter: (x: T) => K): Map<K, Array<T>> {
  const map: Map<K, Array<T>> = new Map();
  this.forEach((item) => {
    const key: K = keyGetter(item);
    const collection: Array<T> | undefined = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

Array.prototype.isEmpty = function(): boolean {
  return this.length === 0;
};

Array.prototype.containsBaseObject = function(valueID: string): boolean {
  return !this.filter(item => item.id === valueID).isEmpty();
};


Number.thisOrZero = (value: number | null | undefined): number => (value !== undefined && value !== null) ? value : 0;

Number.prototype.toHex = function(): string {
  return this.toString(16);
};

export class Utility {

  static getUUID(): string {
    return uuid4();
  }

  static getObjectId(id: string): string {
    if (id.includes('/')) {
      return id.split('/', 2)[1];
    } else {
      return id;
    }
  }

  static updateObjectInData(object: any, data: any[]): void {
    for (const index in data) {
      if (data[index].id === object.id) {
        data[index] = object;
        break;
      }
    }
  }

  static deleteObjectFromArray(array: any[], object: any): void {
    const objectIndex = array.findIndex(i => this.getObjectId(i.id) === this.getObjectId(object.id));
    if (objectIndex !== -1) {
      array.splice(objectIndex, 1);
    }
  }

  static deleteObjectFromArrayById(array: any[], objectId: string): void {
    const objectIndex = array.findIndex(i => this.getObjectId(i.id) === this.getObjectId(objectId));
    if (objectIndex !== -1) {
      array.splice(objectIndex, 1);
    }
  }

  static convertTimestampToLocalDateString(timestamp: number): string {
    return (timestamp && timestamp > 0) ? new Date(timestamp).toLocaleDateString() : 'common.date-not-set';
  }

  static transformTimestampToUserFriendlyStringDateTime(timestamp: number): string {
    const date = new Date(timestamp);
    return timestamp && timestamp > 0 ? date.getDate() +
      '/' + (date.getMonth() + 1) +
      '/' + date.getFullYear() +
      ' ' + (date.getHours().toString().length === 2 ? date.getHours() : date.getHours().toString().length === 1 ?
        ('0' + date.getHours()) : '00') +
      ':' + (date.getMinutes().toString().length === 2 ? date.getMinutes() : date.getMinutes().toString().length === 1 ?
        ('0' + date.getMinutes()) : '00') +
      ':' + (date.getSeconds().toString().length === 2 ? date.getSeconds() : date.getSeconds().toString().length === 1 ?
        ('0' + date.getSeconds()) : '00') : 'common.date-not-set';
  }

  static convertSecondsToDurationObject(secondsInserted: number): { days: number, hours: number, minutes: number, seconds: number } {
    let seconds = secondsInserted;
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    hours = hours - days * 24;
    minutes = minutes - (days * 24 + hours) * 60;
    seconds = seconds - (days * 24 * 60 + hours * 60 + minutes) * 60;
    return {days, hours, minutes, seconds};
  }

  static convertDurationObjectToSeconds(days?: number, hours?: number, minutes?: number, seconds?: number): number {
    return (seconds ?? 0) + 60 * ((minutes ?? 0) + 60 * ((hours ?? 0) + +24 * (days ?? 0)));
  }

  static transformDurationObjectToUserFriendlyString(duration: { days: number, hours: number, minutes: number, seconds: number },
                                                     service: TranslateService): string {
    if (duration.days + duration.hours + duration.minutes + duration.seconds <= 0) {
      return 'common.duration-not-set';
    }
    let finalString = '';
    if (duration.days) {
      finalString += duration.days + (service.translate(duration.days === 1 ? 'common.day' : 'common.days')) + ' ';
    }
    if (duration.hours) {
      finalString += duration.hours + 'h ';
    }
    if (duration.minutes) {
      finalString += duration.minutes + 'min ';
    }
    if (duration.seconds) {
      finalString += duration.seconds + 'sec ';
    }
    return finalString;
  }

  static transformTimestampToUserFriendlyString(timestamp: number, translateService: TranslateService): string {
    return timestamp && timestamp > 0 ? this.transformDurationObjectToUserFriendlyString(
      this.convertSecondsToDurationObject(timestamp), translateService
    ) : translateService.translate('common.date-not-set');
  }

  static getLocaleId(locale: string): string {
    return locale.substring(0, 2);
  }

  static showViolationsIfOccurs<T extends NotificationService>(errResponse: HttpErrorResponse, notificationService: T): void {
    const error = JSON.parse(errResponse.error);
    if (error && error.message) {
      notificationService.displayNotificationToast(error.message, NotificationMessageType.ERROR);
    }
  }

  static getRandomColor(): string {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  static getNegativeColor(color: string): string {
    const newR = 255 - parseInt(color.slice(1, 3), 16);
    const newG = 255 - parseInt(color.slice(3, 5), 16);
    const newB = 255 - parseInt(color.slice(5, 7), 16);
    return '#' + newR.toHex() + newG.toHex() + newB.toHex();
  }

  static compareNumbers(a: number | string, b: number | string): number {
    return a > b ? 1 : a === b ? 0 : -1;
  }

  static getErrorMessageFromResponse(error: HttpErrorResponse, alternateErrorInfo?: string): string {
    const message = JSON.parse(error.error)[0]?.errorUIkey;
    return message ? message : alternateErrorInfo ? alternateErrorInfo : 'common.no-error-info';
  }

  static getObjectTypeNameById(module: string, id: string): string {
    return module + '.' + id.split('/')[0];
  }

  static moveSelectedElementsBetweenTables(selectedItems: any[], fromTableData: any[], toTableData: any[]): void {
    selectedItems?.forEach(selectedItem => {
      const elementIndex = fromTableData?.findIndex(record => record.id === selectedItem.id);
      const elementToMove = fromTableData[elementIndex];
      toTableData.push(elementToMove);
      fromTableData.splice(elementIndex, 1);
    });
  }

  static isTerminalDevice(): boolean {
    return navigator.userAgent.includes('CrOS');
  }

  static isMobileDevice(): boolean {
    return navigator.userAgent.includes('PDA');
  }

  static getDeepClone(object: any): any {
    return lodash.cloneDeep(object);
  }

  static transformCamelCaseToKebabCase(str: string): string {
    return str.split('').map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    }).join('');
  }

  static getSelectItemFromEnumEntity(entity: BaseEnumEntity): SelectItem {
    return {label: entity.translations[0].translationValue, value: entity};
  }

  static getSelectItemArrayFromEnumEntityArray(entities: BaseEnumEntity[]): SelectItem[] {
    return entities.map(entity => this.getSelectItemFromEnumEntity(entity));
  }

  static forceRouterReload(router: Router, url: string): void {
    router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    router.onSameUrlNavigation = 'reload';
    router.navigate([url]);
  }

  static displayValueIfPresent(value: any, translateService: TranslateService): any {
    return value ? value : translateService.translate('common.no-data');
  }

}
