import {Injectable} from '@angular/core';
import {ServiceSchemaStepCtrl} from '../../core/sdk/controllers-productforservice';
import {ProductForService, ServiceSchemaStep} from '../../core/sdk/model-productforservice';
import {Observable} from 'rxjs';
import {Utility} from '../utilities/utility';
import {WorkspaceGroup} from '../../core/sdk/bighero-model';
import {FiringSchema} from '../../core/sdk/model-firingschema';
import {NotificationMessageType} from '../enums/NotificationMessageType';
import {NotificationService} from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceSchemaStepService {

    private serviceSchemaStepData: ServiceSchemaStep[];
    private openForEditionModeIdList: string[] = [];
    private productForService: ProductForService;
    private newlyAddedServiceSchemaSteps: ServiceSchemaStep[] = [];

    private currentWorkspaceGroup: WorkspaceGroup;
    private currentFiringSchema: FiringSchema;

    constructor(private serviceSchemaStepCtrl: ServiceSchemaStepCtrl) {
    }

    public getNewEmptyServiceSchemaStep(productForService: ProductForService): ServiceSchemaStep {
        return {
            id: 'service-schema-step/' + Utility.getUUID(),
            index: 0,
            name: '',
            productForService,
            firingSchema: null,
            stepDuration: Utility.convertSecondsToDurationObject(0),
            timestamp: Date.now(),
            description: '',
            type: null,
            version: 0,
            quantityOfMiddleProductsAfterPostProcessing: 0,
            workspaceGroup: null
        };
    }

    public setServiceSchemaData(data: ServiceSchemaStep[]): void {
        this.flushAllData();
        this.serviceSchemaStepData = data;
    }

    public tagServiceSchemaStepAsOpen(stepId: string): void {
        this.openForEditionModeIdList.push(stepId);
    }

    public set currentlySelectedWorkspaceGroup(workspaceGroup: WorkspaceGroup) {
        this.currentWorkspaceGroup = workspaceGroup;
    }

    public get currentlySelectedWorkspaceGroup(): WorkspaceGroup {
        return this.currentWorkspaceGroup;
    }

    public set currentlySelectedFiringSchema(firingSchema: FiringSchema) {
        this.currentFiringSchema = firingSchema;
    }

    public get currentlySelectedFiringSchema(): FiringSchema {
        return this.currentFiringSchema;
    }

    public setCurrentProductForService(productForService: ProductForService): void {
        this.productForService = productForService;
    }

    public getCurrentProductForService(): ProductForService {
        return this.productForService;
    }

    public tagServiceSchemaStepAsClosed(stepId: string): void {
        const index = this.openForEditionModeIdList.findIndex(serviceStepId => serviceStepId === stepId);
        this.openForEditionModeIdList.splice(index, 1);
    }

    public tagOtherOpenServiceSchemaStepsAsClosed(stepId: string): void {
        this.openForEditionModeIdList.filter(serviceStepId => serviceStepId !== stepId).forEach(step => {
            this.openForEditionModeIdList.splice(this.openForEditionModeIdList.indexOf(step), 1);
        });
    }

    public isStepOpen(stepId: string): boolean {
        return this.openForEditionModeIdList != null &&
            this.openForEditionModeIdList.find(serviceStepId => serviceStepId === stepId) != null;
    }

    public getServiceSchemaStepIndex(serviceSchemaStep: ServiceSchemaStep): number {
        return this.serviceSchemaStepData.findIndex(step => step.id === serviceSchemaStep.id);
    }

    public getServiceSchemaData(): ServiceSchemaStep[] {
        return this.serviceSchemaStepData;
    }

    public changeIndexLower(step: ServiceSchemaStep, notificationService: NotificationService): void {
        const serviceSchemaStepIndex = this.getServiceSchemaStepIndex(step);
        if (serviceSchemaStepIndex === this.getServiceSchemaData().length - 1) {
            notificationService.displayNotificationToast('products.this-is-last-step',
                NotificationMessageType.WARNING);
        } else {
            this.getServiceSchemaData()[serviceSchemaStepIndex] = this.getServiceSchemaData()[serviceSchemaStepIndex + 1];
            this.getServiceSchemaData()[serviceSchemaStepIndex + 1] = step;
        }
    }

    public changeIndexHigher(step: ServiceSchemaStep, notificationService: NotificationService): void {
        const serviceSchemaStepIndex = this.getServiceSchemaStepIndex(step);
        if (serviceSchemaStepIndex === 0) {
            notificationService.displayNotificationToast('products.this-is-first-step',
                NotificationMessageType.WARNING);
        } else {
            this.getServiceSchemaData()[serviceSchemaStepIndex] = this.getServiceSchemaData()[serviceSchemaStepIndex - 1];
            this.getServiceSchemaData()[serviceSchemaStepIndex - 1] = step;
        }
    }

    public isItNewStep(serviceSchemaStep: ServiceSchemaStep): boolean {
        return this.newlyAddedServiceSchemaSteps.findIndex(step => step.id === serviceSchemaStep.id) !== -1;
    }

    public removeServiceSchemaStepFromData(serviceSchemaStep: ServiceSchemaStep): void {
        this.serviceSchemaStepData.splice(this.getServiceSchemaStepIndex(serviceSchemaStep), 1);
    }

    public pushNewStepToServiceSchemaData(serviceStep: ServiceSchemaStep): void {
        this.newlyAddedServiceSchemaSteps.push(serviceStep);
        this.serviceSchemaStepData.push(serviceStep);
    }

    public tagAsDeleted(index: number): void {
        this.serviceSchemaStepData[index].productForService = null;
    }

    public flushAllData(): void {
        this.serviceSchemaStepData = [];
        this.openForEditionModeIdList = [];
        this.newlyAddedServiceSchemaSteps = [];
    }

    public tagAllServiceSchemaStepsAsDeleted(): void {
        this.serviceSchemaStepData.forEach(step => step.productForService = null);
    }

    public getProductForServiceSchemaSteps(productForServiceId: string): Observable<ServiceSchemaStep[]> {
        return this.serviceSchemaStepCtrl.getProductForServiceSchemaSteps(Utility.getObjectId(productForServiceId));
    }

    public updateSingleServiceSchemaStep(serviceSchemaStep: ServiceSchemaStep): Observable<ServiceSchemaStep> {
        return this.serviceSchemaStepCtrl.updateObject(serviceSchemaStep);
    }

    public deleteSingleServiceSchemaStep(serviceSchemaStepId: string, productForServiceId: string): Observable<ServiceSchemaStep[]> {
        return this.serviceSchemaStepCtrl.deleteServiceSchemaStep(Utility.getObjectId(serviceSchemaStepId),
            Utility.getObjectId(productForServiceId));
    }

    public updateServiceSchemaStepList(serviceSchemaStepList: ServiceSchemaStep[], productForServiceID: string)
        : Observable<ServiceSchemaStep[]> {
        return this.serviceSchemaStepCtrl.updateServiceSchemaStepList(serviceSchemaStepList, Utility.getObjectId(productForServiceID));
    }

    public importFromProductionSchemaTemplate(productForServiceId: string, productionSchemaTemplateId: string)
        : Observable<ServiceSchemaStep[]> {
        return this.serviceSchemaStepCtrl.importFromProductionSchemaTemplate(Utility.getObjectId(productForServiceId),
            Utility.getObjectId(productionSchemaTemplateId));
    }
}
